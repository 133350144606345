import { useState, useMemo } from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldConfig } from '../interfaces';
import { getFieldConfigByResourceName } from '../utils/utils';

export type SortConfig = {
  field: string | null;
  type: 'asc' | 'desc';
};

// useSortableData is a custom hook that sorts a list of items based on a given sort configuration.
// It provides sorting functionality for tables or lists where items can be sorted by different fields in ascending or descending order.
// The hook maintains the current sort configuration, handles sorting logic, and returns the sorted list of items.
// It also provides a function, 'requestSort', to change the sort order and a helper function, 'getSortCaret', to display sort direction icons.
export const useSortableData = <T,>(
  items: T[],
  showPopup: boolean = false,
  initialSortConfig: SortConfig = { field: null, type: 'asc' }
) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>(initialSortConfig);

  const sortedItems = useMemo(() => {
    if (!items || items.length === 0) {
      return items || [];
    }

    let sortableItems = [...items];
    if (sortConfig.field !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.field as keyof T];
        const bValue = b[sortConfig.field as keyof T];

        if (aValue < bValue) {
          return sortConfig.type === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.type === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (field: string) => {
    if (showPopup) {
      return;
    }
    let type: 'asc' | 'desc' = 'asc';
    if (sortConfig.field === field && sortConfig.type === 'asc') {
      type = 'desc';
    }
    setSortConfig({ field, type });
  };

  const getSortCaret = (
    columnField: string,
  ) => {
    // Check if the sortConfig matches the current fieldConfig's resourceName
    if (!sortConfig || sortConfig.field !== columnField) {
      return null;
    }

    // Return the appropriate sort caret icon based on the sort type
    return sortConfig.type === 'asc' ? (
      <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
    ) : (
      <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
    );
  };

  return { items: sortedItems, requestSort, sortConfig, setSortConfig, getSortCaret };
};
