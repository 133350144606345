import { ChangeEvent } from 'react';

export function handleInputChange<T>(
  e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  setFormValues: React.Dispatch<React.SetStateAction<T>>
) {
  const { id, value } = e.target;
  const parsedValue = value === 'true' ? true : value === 'false' ? false : value;

  setFormValues((prev) => ({ ...prev, [id]: parsedValue }));
}

export function handleToggleChange<T>(
  e: ChangeEvent<HTMLInputElement>,
  id: keyof T,
  setFormValues: React.Dispatch<React.SetStateAction<T>>
) {
  setFormValues((prev) => ({
    ...prev,
    [id]: e.target.checked,
  }));
}