import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { IGlobalMeasureDetails, Setting } from '../../interfaces';
import SkeletonCard from '../skeleton/SkeletonCard';
import GenericInfosCard from '../GenericInfosCard';
import { usePermissions } from '../../hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';
import GlobalMeasureDetailsModal from './modal/GlobalMeasureDetailsModal';
import { formatDate } from '../../utils/utils';

interface GlobalMeasureDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null;
    onSubmitSuccess: (settingsId: number) => void;
}

const GlobalMeasureDetails: React.FC<GlobalMeasureDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const details = setting?.details as IGlobalMeasureDetails;

    console.log(details);

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
        ];
        return info1;
    }

    function getInfo3() {
        const info3 = [
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info3;
    }

    function renderMeasureDetailsTable() {
        if (!details) return null;

        return (
            <Table responsive className="mt-4">
                <thead>
                    <tr>
                        <th>Maßnahmennummer</th>
                        <th>Bezeichnung</th>
                        <th>Startdatum</th>
                        <th>Enddatum</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(details).map(key => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{details[key].designation}</td>
                            <td>{formatDate(details[key].startDate, 'd.m.Y')}</td>
                            <td>{formatDate(details[key].endDate, 'd.m.Y')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                {setting && (
                    <GlobalMeasureDetailsModal
                        buttonName="Bearbeiten"
                        modalTitle={`${setting.title} bearbeiten`}
                        setting={setting}
                        onSubmitSuccess={onSubmitSuccess}
                        isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')}
                    />
                )}
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo3()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        renderMeasureDetailsTable()
                    )}
                </Col>
            </Row>
        </>
    );
};

export default GlobalMeasureDetails;
