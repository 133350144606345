import { useLocation, useNavigate } from 'react-router-dom';

const useResetUrlParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const resetUrlParams = () => {
    const baseUrl = location.pathname;
    navigate(baseUrl, { replace: true });
  };

  return resetUrlParams;
};

export default useResetUrlParams;
