import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface LastPathInfo {
  path: string;
  timestamp: number;
}

export const useSaveLastUrl = (): void => {
  const location = useLocation();
  const params = useParams<{ companyId?: string }>();

  useEffect(() => {
    const { companyId } = params;

    let currentPath = location.pathname;
    if (companyId) {
      const pathWithoutCompanyId = currentPath.replace(`/${companyId}`, '');
      currentPath = pathWithoutCompanyId.startsWith('/') ? pathWithoutCompanyId : `/${pathWithoutCompanyId}`;
      localStorage.setItem('companyId', companyId);
    }

    const timestamp: number = new Date().getTime();
    const lastPathInfo: LastPathInfo = { path: currentPath, timestamp };
    localStorage.setItem('lastPath', JSON.stringify(lastPathInfo));
  }, [location, params]);
};
