// auth
import SignIn from '../views/dashboard/auth/sign-in'

// errors
import Error404 from '../views/dashboard/errors/error404'
import Error500 from '../views/dashboard/errors/error500'
import Maintenance from '../views/dashboard/errors/maintenance'

export const SimpleRouter = [
    {
        path: ':companyId/login',
        element: <SignIn />
    },
    {
        path: 'errors/error404',
        element: <Error404 />
    },
    // Utilities
    {
        path: 'errors/error500',
        element: <Error500 />
    },
    {
        path: 'errors/maintenance',
        element: <Maintenance />
    }
]