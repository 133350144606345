import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { smileyColorMap, smileyMap } from './filter/iconAndColorMappings';

interface ParticipantInitialsComponentProps {
    initials: string;
    size?: number;
    smileyLevel?: 1 | 2 | 3 | 4 | 5;
}

/**
 * ParticipantInitialsComponent
 * 
 * This component displays the initials of a participant inside a circular container,
 * with an optional smiley icon representing a mood or status.
 * 
 * Props:
 * - `initials`: The initials to display within the circular container.
 * - `size`: The size of the circular container (default is 60px).
 * - `smileyLevel`: A numeric value (1 to 5) that determines which smiley icon and color
 *   to display. Each level corresponds to a different smiley and color.
 */
const ParticipantInitialsComponent: React.FC<ParticipantInitialsComponentProps> = ({
    initials,
    size = 60,
    smileyLevel = 5,
}) => {
    const fontSize = size * 0.4;
    const smileyIcon = smileyLevel ? smileyMap[smileyLevel] : null;
    const smileyColor = smileyLevel ? smileyColorMap[smileyLevel] : 'transparent';

    return (
        <div className="participant-initials" style={{ width: size, height: size }}>
            <span className="participant-initials-text" style={{ fontSize }}>{initials}</span>
            {smileyIcon && (
                <div
                    className="smiley-icon-container"
                >
                    <FontAwesomeIcon fontSize={fontSize * 1.3} color={smileyColor} icon={smileyIcon} />
                </div>
            )}
        </div>
    );
};

export default ParticipantInitialsComponent;
