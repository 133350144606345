import React from 'react';
import { Accordion } from 'react-bootstrap';
import { formatDate } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock, faHourglassHalf, faLock, faFileAlt } from '@fortawesome/pro-solid-svg-icons';

interface Module {
  slug: string;
  title: string;
  lwId: string;
  durationUes: string;
  startDate: string;
  endDate: string;
  status: string;
}

interface Modules {
  [key: string]: Module;
}

interface ModuleListProps {
  modules: Modules;
}

let firstOpenStatusFound = false;

const ModuleList: React.FC<ModuleListProps> = ({ modules: courses }) => {
  const getFormattedDate = (dateString: string) => {
    let time = formatDate(dateString, `H:i:s`);
    let format = 'd.m.Y';

    if (time !== '00:00:00') {
      format += ' H:i:s';
    }

    return formatDate(dateString, format);
  };

  const getModuleStatusIcon = (status: string) => {
    let icon = faFileAlt;
    let color = ''

    switch (status) {
      case 'sended':
        icon = faUnlock;
        color = '#2a7854'
        break;
      case 'open':
        if (!firstOpenStatusFound) {
          icon = faHourglassHalf;
          firstOpenStatusFound = true;
          color = '#c15b3d'
        } else {
          return null;
        }
        break;
      case 'closed':
        icon = faLock;
        color = '#a33a3a'
        break;
      default:
        icon = faFileAlt;
    }

    return <FontAwesomeIcon style={{ color }} icon={icon} className="me-2" />;
  };
  firstOpenStatusFound = false;

  return (
    <Accordion className="custom-accordion">
      {Object.values(courses).map((course, index) => (
        <Accordion.Item className='custom-accordion-item' eventKey={course.slug} key={course.slug}>
          <Accordion.Header className="custom-accordion-header">
            {getModuleStatusIcon(course.status)}
            <span>{course.title}</span>
          </Accordion.Header>
          <Accordion.Body className='py-0'>
            <p className="">
              <strong>Position:</strong> {index + 1} &nbsp;
              <strong>Kürzel:</strong> {course.slug} &nbsp;
              <strong>Status:</strong> {course.status} &nbsp;
            </p>
            <p>
              <strong>Startdatum:</strong> {getFormattedDate(course.startDate)} &nbsp;
              <strong>Enddatum:</strong> {getFormattedDate(course.endDate)} &nbsp;
            </p>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default ModuleList;