import React from 'react';

interface SkeletonRowProps {
  ignoreFirstColumn?: boolean;
  columnCount: number;
}

const SkeletonRow: React.FC<SkeletonRowProps> = ({ ignoreFirstColumn = false, columnCount }) => {
  return (
    <tr>
      {ignoreFirstColumn && <td></td>}
      {Array.from({ length: columnCount }).map((_, index) => (
        <td key={index}><div className="skeleton-loader" style={{ height: '20px' }}></div></td>
      ))}
    </tr>
  );
};

export default SkeletonRow;
