import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useToast } from '../services/context/ToastContext';

interface ViewRenameModalProps {
    show: boolean;
    handleClose: () => void;
    handleConfirm: (newName: string) => void;
    currentName: string;
}

// ViewRenameModal is a modal component for renaming a view.
// It displays the current view name and allows the user to input a new name with a 15-character limit.
// The modal validates the input, ensuring the name is not empty and doesn't exceed the character limit.
// The 'handleConfirm' function is called with the new name when the user confirms the change.
// If the name remains unchanged, the modal simply closes without making changes.
// Remaining characters are dynamically shown and change color based on how many are left (green, yellow, or red).
const ViewRenameModal: React.FC<ViewRenameModalProps> = ({ show, handleClose, handleConfirm, currentName }) => {
    const [newName, setNewName] = useState<string>(currentName);
    const [remainingChars, setRemainingChars] = useState<number>(15 - currentName.length);
    const inputRef = useRef<HTMLInputElement>(null);
    const { showToast } = useToast();

    const handleSave = () => {
        if (newName.trim() === "") {
            showToast('Name darf nicht leer sein.', true);
        } else if (newName.length > 15) {
            showToast('Name darf nicht länger als 15 Zeichen sein.', true);
        } else if (newName !== currentName) {
            handleConfirm(newName);
            handleClose();
            setNewName('');
        } else {
            handleClose();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSave();
        }
    };

    useEffect(() => {
        if (show) {
            setNewName(currentName);
            setRemainingChars(15 - currentName.length);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [currentName, show]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewName(value);
        setRemainingChars(15 - value.length);
    };

    const getRemainingCharsClass = () => {
        if (remainingChars > 5) return 'text-success';
        if (remainingChars > 2) return 'text-warning';
        return 'text-danger';
    };

    return (
        <Modal
            centered
            size="lg"
            backdrop="static"
            keyboard={true}
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Ansicht umbenennen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formNewName">
                        <Form.Label className='text-black'>Name</Form.Label>
                        <Form.Control
                            ref={inputRef}
                            className='text-black bg-grey'
                            type="text"
                            placeholder="Neuen Namen eingeben"
                            value={newName}
                            maxLength={15}
                            onChange={handleNameChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                        />
                        <Form.Text className={getRemainingCharsClass()}>
                            {remainingChars} Zeichen verbleibend
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-soft-primary" onClick={handleClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewRenameModal;
