import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Attendance } from '../../interfaces';
import { usePermissions } from '../../hooks/usePermissions';
import AttendanceDetails from '../../components/attendances/detail/AttendanceDetails';
import { mockAttendances } from './attendances';

function AttendanceDetail() {
  const { attendanceId } = useParams();
  const [attendance, setAttendance] = useState<Attendance | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewAttendances, 'read');

      if (hasPermission) {
        attendanceId && fetchAttendance(attendanceId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [attendanceId, permissionsLoaded]);

  const fetchAttendance = async (attendanceId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/attendances/${attendanceId}`);
      // const settingResponse = response.data;
      //setAttendance(settingResponse);
      setAttendance(mockAttendances[Number(attendanceId) - 1]);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = () => {
    setAttendance(null)
    attendanceId && fetchAttendance(attendanceId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <AttendanceDetails attendance={attendance} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></AttendanceDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default AttendanceDetail;
