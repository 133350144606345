import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { IndexRouters } from './router';
import { ToastProvider } from './services/context/ToastContext';

const router = createBrowserRouter(
  [
    // {
    //   path: '/',
    //   element: <Index />,
    // },
    ...IndexRouters,
  ],
  { basename: process.env.PUBLIC_URL }
);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

ReactDOM.createRoot(rootElement).render(
  <StrictMode>
    <Provider store={store}>
      <ToastProvider>
        <App>
          <RouterProvider router={router} />
        </App>
      </ToastProvider>
    </Provider>
  </StrictMode>
);
