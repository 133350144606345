import { FieldConfig } from "../../interfaces";
import { getFieldConfigByResourceName } from "../../utils/utils";

interface RenderHeaderOptions {
  entityLabels?: Record<string, string>;
}

const defaultRenderHeader = (
  columnKey: string,
  fieldConfigs: FieldConfig[],
  requestSort: (key: string) => void,
  getSortCaret: (columnField: string) => JSX.Element | null,
  options: RenderHeaderOptions = {}
) => {
  const { entityLabels = {} } = options;
  const fieldConfig = getFieldConfigByResourceName(fieldConfigs, columnKey);
  const sortField = fieldConfig?.resourceName || columnKey;

  // Get the label based on the entity name if it exists in the mapping
  const entityLabel = fieldConfig?.entityName
    ? entityLabels[fieldConfig.entityName] || ''
    : '';

  return (
    <th
      key={sortField}
      className="cursor-pointer position-relative table-cell-wrap"
      scope="col"
      onClick={() => requestSort(sortField)}
      title={fieldConfig?.fieldLabel || columnKey}
    >
      {fieldConfig?.fieldLabel || columnKey} 
      {entityLabel && <small> ({entityLabel})</small>}
      <div className="position-absolute" style={{ right: 0, top: '8px' }}>
        {getSortCaret(columnKey)}
      </div>
    </th>
  );
};

export { defaultRenderHeader };
