import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import RoleDetails from '../../components/roles/detail/RoleDetails';
import { Role } from '../../interfaces';

function RoleDetail() {
  const { roleId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [role, setRole] = useState<Role>();
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewRoles, 'read');

      if (hasPermission) {
        roleId && fetchRole(roleId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [roleId, permissionsLoaded]);

  const fetchRole = async (roleId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/roles/${roleId}`);
      const certificateResponse = response.data;
      setRole(certificateResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = () => {
    setRole(undefined)
    roleId && fetchRole(roleId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <RoleDetails role={role} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></RoleDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default RoleDetail;
