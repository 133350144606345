import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Company, CompanyEnum } from '../utils/enum';

import oc from '../assets/images/oc.svg';
import cn from '../assets/images/cn.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissions } from '../hooks/usePermissions';


const companyImages = {
  oc: oc,
  cn: cn,
  nc: ''
};

interface CompanyDropdownProps {
  sidebarClosed?: boolean
}

const CompanyDropdown: React.FC<CompanyDropdownProps> = ({
  sidebarClosed,
}) => {
  const { companyId = 'oc' } = useParams();
  const [selectedCompany, setSelectedCompany] = useState<Company>(companyId as Company);
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const handleSelectCompany = (company: Company) => {
    setSelectedCompany(company);
    navigate(`/${company}/`, { replace: true });
    window.location.reload();
  };

  return (
    <Dropdown className="rounded" style={{ backgroundColor: '#F9F9F9' }}>
      <Dropdown.Toggle variant="link" id="dropdown-certification-basic" className={`text-black ${sidebarClosed && 'ps-1'}`}>
        <img src={companyImages[selectedCompany]} alt={CompanyEnum[selectedCompany]} className="me-2" style={{ height: '18px' }} />
        {!sidebarClosed && CompanyEnum[selectedCompany]}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {permissions.companies.map((company, i) => (
          <Dropdown.Item key={i} onClick={() => handleSelectCompany(company)}>
            <img src={companyImages[company]} alt={CompanyEnum[company]} className="me-2" style={{ height: '18px' }} />
            {CompanyEnum[company]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CompanyDropdown;
