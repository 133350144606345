import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../../services/context/ToastContext';

interface TableDropzoneWrapperProps {
    onFilesChange: (files: File[]) => void;
    children: React.ReactNode;
    isDisabled?: boolean;
}

const TableDropzoneWrapper: React.FC<TableDropzoneWrapperProps> = ({ onFilesChange, children, isDisabled }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { showToast } = useToast();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setIsDragging(false);
        setError(null);
        onFilesChange(acceptedFiles);
    }, [onFilesChange]);

    const onDropRejected = useCallback((fileRejections: any) => {
        setIsDragging(false);
        const errors = fileRejections.map((rejection: any) => rejection.errors).flat();
        const sizeError = errors.find((error: any) => error.code === 'file-too-large');
        if (sizeError) {
            showToast('Datei ist zu groß. Maximale Dateigröße ist 50MB.', true);
        }
    }, [showToast]);

    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
        onDrop,
        onDropRejected,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
        onDragOver: () => setIsDragging(true),
        maxSize: 50 * 1024 * 1024, // Set maximum file size to 50MB
        disabled: isDisabled, // Disable dropzone when isDisabled is true
    });

    return (
        <div {...(isDisabled ? {} : getRootProps())} className={`table-dropzone-wrapper ${isDisabled ? '' : 'active-dropzone'}`}>
            <div>
                {children}
            </div>
            {!isDisabled && (
                <div className="dropzone-overlay" style={{ display: isDragging ? 'flex' : 'none' }}>
                    <input {...getInputProps()} />
                    <div className="d-flex justify-content-center align-items-center flex-column text-white">
                        <FontAwesomeIcon size="2x" icon={faUpload} />
                        <p>Dateien hier ablegen ...</p>
                    </div>
                </div>
            )}
            {error && <div className="error-message text-danger">{error}</div>}
        </div>
    );
};

export default TableDropzoneWrapper;
