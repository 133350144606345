import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Setting, WvContractEmailTemplate } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Editor } from '@tinymce/tinymce-react';
import { handleInputChange } from '../../../utils/form/utils';

type WvContractEmailTemplateModalProps = {
    modalTitle: string;
    buttonName: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    isDisabled?: boolean
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    subject: string
    body: string
}

const WvContractEmailTemplateModal: React.FC<WvContractEmailTemplateModalProps> = ({
    modalTitle,
    buttonName,
    setting,
    onSubmitSuccess,
    isDisabled
}) => {

    const fileNames = setting?.details as WvContractEmailTemplate

    const { show, message, error, showToast, hideToast } = useToast();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        subject: fileNames?.title,
        body: fileNames?.body,
    });
    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });


    useEffect(() => {
        if (!showModal) {
            setFormValues(initialFormValues);
        }
    }, [showModal]);

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                handleClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: {
                title: formValues.subject,
                body: formValues.body
            }
        };
    }

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        disabled = false,
        marginBottom = 3
    ) => (
        <Form.Group className={`mb-${marginBottom} w-100`} controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}

            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={5}
                    type={type}
                    value={formValues[id]}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={type !== 'file' ? formValues[id] : ''}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    )

    const createEditorGroup = (
        id: keyof FormValues,
        label: string,
        initialValue: string
    ) => (
        <Form.Group className="mb-3 w-100" controlId={id}>
            <Form.Label>{label}</Form.Label>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                initialValue={initialValue}
                init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'help',
                        'wordcount',
                    ],
                    toolbar: 'undo redo | bold italic underline forecolor | alignleft aligncenter alignright alignjustify'
                }}
                onEditorChange={(content) => {
                    setFormValues((prev: any) => ({
                        ...prev,
                        [id]: content,
                    }));
                }}
            />
        </Form.Group>
    );

    return (
        <>
            <Button disabled={isDisabled} variant="primary" onClick={handleShow}>
                {buttonName}
            </Button>

            <Modal
                centered
                size="xl"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen='xl-down'
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={handleClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>
                            <Row>
                                <Col>
                                    {createFormGroup('title', 'Titel', 'text', true, true)}
                                </Col>
                                <Col>
                                    {createFormGroup('description', 'Beschreibung', 'textarea', false)}
                                </Col>
                            </Row>

                            <div className="horizontal-line my-3"></div>

                            <Row>
                                <Col >
                                    {createFormGroup('subject', 'Titel/Betreff')}
                                </Col>

                            </Row>

                            <Row>
                                <Col>
                                    {createEditorGroup(
                                        'body',
                                        'Nachricht',
                                        fileNames.body ?? ''
                                    )}
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default WvContractEmailTemplateModal;
