import React from 'react';
import { Form } from 'react-bootstrap';

/* Props for the FormGroup component */
interface FormGroupProps<T> {
    id: keyof T;
    label: string;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    value: T[keyof T];
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    nameError?: string | null;
}

/* 
 * FormGroup component for rendering labeled form controls.
 * Supports both text inputs and textareas based on the `type` prop.
 */
const FormGroup = <T,>({
    id,
    label,
    type = 'text',
    required = false,
    disabled = false,
    value,
    onChange,
    nameError,
}: FormGroupProps<T>) => {
    return (
        <Form.Group className="mb-3 w-100 text-black" controlId={id as string}>
            {/* Render the label if provided */}
            {label && <Form.Label>{label}</Form.Label>}

            {/* Conditionally render a textarea or input based on the `type` prop */}
            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={5}
                    value={value as string}
                    onChange={onChange}
                    required={required}
                    className="bg-grey text-black"
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={value as string}
                    onChange={onChange}
                    required={required}
                    className="bg-grey text-black"
                    disabled={disabled}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>

            {/* Show an error message if `nameError` is provided and the field is 'name' */}
            {nameError && id === 'name' && (
                <div className="text-danger small">
                    {nameError}
                </div>
            )}
        </Form.Group>
    );
};

export default FormGroup;
