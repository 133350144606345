import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../../GenericInfosCard';
import { Product, } from '../../../interfaces';
import SkeletonCard from '../../skeleton/SkeletonCard';
import AddEditProductModal from '../modal/AddEditProductModal';
import { ProductTypesEnum, getEnumValue } from '../../../utils/enum';

interface ProductDetailsProps {
    isLoading?: boolean;
    product?: Product | null
    onSubmitSuccess: (productId: number) => void;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
    isLoading,
    product,
    onSubmitSuccess
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: product?.title || '-',
            },
            {
                label: 'Status',
                value: product?.status || '-',
            },
            {
                label: 'Typ',
                value: getEnumValue(ProductTypesEnum, product?.type),
            },
            {
                label: 'LW ID',
                value: product?.lwId || '-',
            },
            {
                label: 'LW Link',
                value: product?.lwLink || '-',
            },
            {
                label: 'Kürzel',
                value: product?.slug || '-',
            },
            {
                label: 'Kürzel (neue LW)',
                value: product?.newLwSlug || '-',
            },
            {
                label: 'Lernwelt - ID (neue LW)',
                value: product?.newLwId || '-',
            },
            {
                label: 'Lernwelt - Link (neue LW)',
                value: product?.newLwLink || '-',
            },
        ];
        return info1;
    }
    function getInfo2() {
        const info2 = [
            {
                label: 'Dauer in Wochen',
                value: product?.durationWeeks.toString() || '-',
            },
            {
                label: "Dauer in UE's",
                value: product?.durationUes.toString() || '-',
            },
            {
                label: 'Dauer in Tagen',
                value: product?.durationDays.toString() || '-',
            },

        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{product?.title}</h4>
                {product && <AddEditProductModal
                    buttonName="Bearbeiten"
                    modalTitle={`${product.title} bearbeiten`}
                    product={product}
                    onSubmitSuccess={onSubmitSuccess}
                >
                </AddEditProductModal>
                }
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Zeiten"
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}

                </Col>
            </Row>
        </>
    );
};

export default ProductDetails;
