import { useEffect, memo, Fragment } from 'react';
import { Outlet } from 'react-router-dom';

//sidebar
import Sidebar from '../../components/partials/dashboard/sidebarstyle/sidebar';

import Loader from '../../components/Loader';

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors';

// Redux Selector / Action
import { useSelector } from 'react-redux';
import { Suspense } from 'react';
import Headerpro from '../../components/partials/pro/headerstyle/header-pro';

const Default = memo((props) => {
  const pageLayout = useSelector(SettingSelector.page_layout);
  const appName = useSelector(SettingSelector.app_name);
  useEffect(() => {});

  return (
    <Fragment>
      <Loader />
      <Sidebar app_name={appName} />
      <main className="main-content">
        <div className={`position-relative d-lg-none`}>
          <Headerpro />
        </div>
        <div className={` ${pageLayout} content-inner pb-0`}>
          <Suspense fallback={<div className="react-load"></div>}>
            <Outlet></Outlet>
          </Suspense>
        </div>
      </main>
    </Fragment>
  );
});

Default.displayName = 'Default';
export default Default;
