import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnumValue } from '../utils/enum';
import { FieldConfig } from '../interfaces';
import { getFieldConfigByResourceName } from '../utils/utils';
import { dealStatusColorMap, dealStatusIconMap } from './filter/iconAndColorMappings';

/**
 * LastDealStatusIndicator Component
 * 
 * This component displays the status of the last deal, including an icon, color, and textual representation.
 * 
 * It takes the following props:
 * - lastDealStatus: A numeric value representing the status of the last deal.
 * - fieldConfigs: An array of field configuration objects to extract status information and display options.
 */
interface LastDealStatusIndicatorProps {
    lastDealStatus: number;
    fieldConfigs: FieldConfig[];
}

const LastDealStatusIndicator: React.FC<LastDealStatusIndicatorProps> = ({ lastDealStatus, fieldConfigs }) => {
    const lastDealStatusIcon = dealStatusIconMap[Number(lastDealStatus)];
    const lastDealStatusColor = dealStatusColorMap[Number(lastDealStatus)];
    const lastDealStatusFieldConfig = getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status');
    const lastDealStatusText = lastDealStatusFieldConfig?.options && getEnumValue(lastDealStatusFieldConfig.options, lastDealStatus?.toString());

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${lastDealStatusColor}`}>
            <FontAwesomeIcon width={15} icon={lastDealStatusIcon} className="me-2" />
            <span>{lastDealStatusText}</span>
        </div>
    );
};

export default LastDealStatusIndicator;
