import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ProgressBarComponentProps {
    now: number;
    label?: string;
    abort?: boolean
}

const ProgressBarComponent: React.FC<ProgressBarComponentProps> = ({ now, label, abort }) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${now}`}>
                    {now}%
                </Tooltip>
            }
        >
            <div className="py-2">
                <div className="progress-bar-container">
                    <div
                        className="progress-bar-overlay"
                        style={{ width: `${100 - now}%` }}
                    />
                    <div
                        className="progress-bar-vertical-line"
                        style={{
                            left: `${now}%`,
                            background: abort ? 'red' : ''
                          }}
                    />
                </div>
                {label && (
                    <div className={`progress-bar-label`}>
                        {label}
                    </div>
                )}
            </div>
        </OverlayTrigger>
    );
};

export default ProgressBarComponent;
