import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { Deal } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import DealDetails from '../../components/deals/detail/DealDetails';

function DealDetail() {
  const { dealId } = useParams();
  const [deal, setDeal] = useState<Deal>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const { show, message, error, showToast, hideToast } = useToast();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewDeals,'read');

      if (hasPermission) {
        dealId && fetchDeal(dealId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [dealId, permissionsLoaded]);

  const fetchDeal = async (dealId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/deals/${dealId}`);
      const dealResponse = response.data;
      setDeal(dealResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = () => {
    setDeal(undefined);
    dealId && fetchDeal(dealId);
  };

  return (
    <Fragment>
      <Row>
      <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <DealDetails deal={deal} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></DealDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default DealDetail;
