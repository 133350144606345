import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

interface SkeletonCardProps {
  sectionCount: number;
  includeImage?: boolean;
  imageFirst?: boolean;
}

const SkeletonCard: React.FC<SkeletonCardProps> = ({
  sectionCount,
  includeImage = false,
  imageFirst = false
}) => {
  const imageSkeleton = (
    <div className="skeleton-loader skeleton-image mb-3" style={{ height: '200px' }}></div>
  );

  const titleAndSections = (
    <>
      <div className="skeleton-loader skeleton-title mb-3" style={{ height: '20px', width: '30%' }}></div>
      <div className='horizontal-line my-3'></div>
      {Array.from({ length: sectionCount }).map((_, index) => (
        <Row key={index} className="mb-3">
          <Col lg={4}>
            <div className="skeleton-loader" style={{ height: '20px', width: '80%' }}></div>
          </Col>
          <Col>
            <div className="skeleton-loader" style={{ height: '20px', width: '90%' }}></div>
          </Col>
        </Row>
      ))}
    </>
  );

  return (
    <Card className="card-block card-stretch card-height">
      <Card.Body>
        {imageFirst && includeImage && imageSkeleton}
        {titleAndSections}
        {!imageFirst && includeImage && imageSkeleton}
      </Card.Body>
    </Card>
  );
};

export default SkeletonCard;
