import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import DeleteModal from '../DeleteModal';
import { MediaItem } from '../../interfaces';
import { usePermissions } from '../../hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';

interface SelectionActionsProps {
  selectedCount: number;
  selectedMedia: { [key: string]: boolean };
  handleDeSelectAll: () => void;
  onSubmitSuccess: () => void;
  media: MediaItem[];
}

const isMediaSecure = (media: MediaItem) => {
  if (media.flags) {
    const flags = JSON.parse(media.flags);
    return flags.secure === true;
  }
  return false;
};

const SelectionActions: React.FC<SelectionActionsProps> = ({
  selectedCount,
  selectedMedia,
  handleDeSelectAll,
  onSubmitSuccess,
  media,
}) => {
  const { userHasPermissionByRight } = usePermissions();
  const selectedMediaIds = Object.keys(selectedMedia)
    .filter(key => selectedMedia[key] === true)
    .map(Number);

  const anySelectedMediaSecure = selectedMediaIds.some(id => {
    const mediaItem = media.find(item => item.id === id);
    return mediaItem && isMediaSecure(mediaItem);
  });

  return (
    <tr>
      <td colSpan={7}>
        <Row
          className="d-flex justify-content-between p-3"
          style={{ backgroundColor: '#EBEEFD' }}
        >
          <Col className="d-flex align-items-center" md={2}>
            <span>{selectedCount} Elemente wurden ausgewählt</span>
            <Button variant="link" onClick={handleDeSelectAll}>
              Auswahl aufheben
            </Button>
          </Col>
          <Col className="d-flex justify-content-end" md={8}>
            {/* <Button variant="link">
              Herunterladen
            </Button>

            <MediumEditModal
              modalTitle="Bearbeiten"
              buttonName="Bearbeiten"
              onSubmitSuccess={onSubmitSuccess}>
            </MediumEditModal> */}

            {!anySelectedMediaSecure && (
              <DeleteModal
                modalTitle="Löschen?"
                buttonName="Löschen"
                entityType='media'
                entityIds={selectedMediaIds}
                onSubmit={onSubmitSuccess}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewMedia, 'delete')}>
              </DeleteModal>
            )}
          </Col>
        </Row>
      </td>
    </tr>
  );
};

export default SelectionActions;
