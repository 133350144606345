// ToastContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ToastContextType {
  show: boolean;
  message: string;
  error: boolean;
  showToast: (message: string, error?: boolean) => void;
  hideToast: () => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState({ show: false, message: '', error: false });

  const showToast = (message: string, error: boolean = false) => {
    setToast({ show: true, message, error });
  };

  const hideToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <ToastContext.Provider value={{ ...toast, showToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
