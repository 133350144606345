import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Satisfaction } from '../../../interfaces';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';
import { SatisfactionQuestionsEnum, getEnumValue } from '../../../utils/enum';
import { getRatingColor, getSatisfactionIcon } from '../../../utils/satisfactions/utils';
import Card from '../../bootstrap/card';

interface SatisfactionDetailsProps {
    isLoading?: boolean;
    satisfaction?: Satisfaction | null;
    onSubmitSuccess: (satisfactionId: number) => void;
}

const SatisfactionDetails: React.FC<SatisfactionDetailsProps> = ({
    isLoading,
    satisfaction,
    onSubmitSuccess
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: satisfaction?.title || '-',
            },
            {
                label: 'Bewertungsschnitt',
                value: satisfaction?.averageRating || '-',
            },
            {
                label: 'Anzahl Fragen',
                value: satisfaction?.amountQuestions || '-',
            },
            {
                label: 'Teilnehmer',
                value: satisfaction?.participant || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Produkt',
                value: satisfaction?.courseName || '-',
            },
            {
                label: 'Kommentar',
                value: satisfaction?.comment || '-',
            },
        ];
        return info2;
    }

    const getQuestionRatings = (): [JSX.Element[], JSX.Element[]] => {
        if (!satisfaction?.ratings) return [[], []];

        const ratings = JSON.parse(satisfaction.ratings);

        const leftColumn: JSX.Element[] = [];
        const rightColumn: JSX.Element[] = [];

        Object.keys(ratings).forEach((key, index) => {
            const question = getEnumValue(SatisfactionQuestionsEnum, key)
            const rating = Number(ratings[key]);

            const item = (
                <div key={index} className="pb-2">
                    <p style={{ fontSize: '16px' }} className="m-0 p-0">{question}</p>
                    <p className="p-0 m-0 text-black">
                        Bewertung: {getSatisfactionIcon(rating)} <span className={getRatingColor(rating)}>{rating}</span>
                    </p>
                </div>
            );

            if (index % 2 === 0) {
                leftColumn.push(item);
            } else {
                rightColumn.push(item);
            }
        });

        return [leftColumn, rightColumn];
    };

    const [leftColumnRatings, rightColumnRatings] = getQuestionRatings();

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{satisfaction?.title}</h4>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            <Card>
                <Card.Body>
                    <Row>
                        <h5 className='mb-3'>Genaue Fragenauswertung</h5>
                        <Col lg={6}>
                            {leftColumnRatings}
                        </Col>
                        <Col lg={6}>
                            {rightColumnRatings}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default SatisfactionDetails;
