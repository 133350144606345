import { memo, Fragment, useEffect, useState } from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/bootstrap/card';
import { Col, Row } from 'react-bootstrap';
import Chart from "react-apexcharts";

const getVariableColor = () => {
  let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
  if (prefix) {
    prefix = prefix.trim()
  }
  const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
  const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
  const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
  const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
  return {
    primary: color1.trim(),
    info: color2.trim(),
    warning: color4.trim(),
    primary_light: color3.trim(),
  };
}
const variableColors = getVariableColor();
const colors = [variableColors.primary, variableColors.info];

const chart3 = {
  options: {
    chart: {
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: colors,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '28%',
        endingShape: 'rounded',
        borderRadius: 5,
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      labels: {
        minHeight: 20,
        maxHeight: 20,
        style: {
          colors: "#8A92A6",
        },
      }
    },
    yaxis: {
      title: {
        text: ''
      },
      labels: {
        minWidth: 19,
        maxWidth: 19,
        style: {
          colors: "#8A92A6",
        },
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands"
        }
      }
    }
  },
  series: [{
    name: 'Successful deals',
    data: [30, 50, 35, 60, 40, 60, 60, 30, 50, 35,]
  }, {
    name: 'Failed deals',
    data: [40, 50, 55, 50, 30, 80, 30, 40, 50, 55]
  }]
}

const Index = memo(() => {
  const { permissionsLoaded, permissions } = usePermissions();
  const [locationStats, setLocationStats] = useState({})
  const [productStats, setProductStats] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const { companyId } = useParams();
  useEffect(() => {
    if (permissionsLoaded) {
      fetchLocationStats()
    }
  }, [permissionsLoaded]);


  const fetchLocationStats = async () => {
    setLocationStats({});
    setIsLoading(true);

    try {

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };


  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3>Dashboard</h3>
        </div>
      </div>

      <Row>
        <Col lg={4}>
          <Card data-aos="fade-up" data-aos-delay="1000">
            <div className="flex-wrap card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-2">Teilnehmer aktiv</h4>
                <h2>333</h2>
              </div>
            </div>

            <Card.Body>
              <Chart className="d-activity" options={chart3.options} series={chart3.series} type="bar" height="230" />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4}>
          <Card data-aos="fade-up" data-aos-delay="1000">
            <div className="flex-wrap card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-2">Teilnehmer in Vorbereitung</h4>
                <h2>555</h2>
              </div>
            </div>
            <Card.Body>
              <Chart className="d-activity" options={chart3.options} series={chart3.series} type="bar" height="230" />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4}>
          <Card data-aos="fade-up" data-aos-delay="1000">
            <div className="flex-wrap card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-2">Beendete Teilnehmer</h4>
                <h2>1233</h2>
              </div>
            </div>
            <Card.Body>
              <Chart className="d-activity" options={chart3.options} series={chart3.series} type="bar" height="230" />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header>    Kursfeedback</Card.Header>
            <Card.Body></Card.Body>
          </Card>
        </Col>
        <Col lg={6}>        <Card>
          <Card.Header> Abwesende Teilnehmer</Card.Header>
          <Card.Body></Card.Body>
        </Card>
        </Col>
      </Row>


    </Fragment>
  );
});

Index.displayName = 'Index';
export default Index;
