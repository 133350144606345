import { useState } from 'react';

import { ComboButtonId } from '../../components/ComboButtonGroup';
import { CertificationStatus, PublicationStatus } from '../../utils/enum';


export const useFilters = () => {
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedCertificationStatusFilter, setSelectedCertificationStatusFilter] = useState<CertificationStatus>();
  const [selectedPublicationStatusFilter, setSelectedPublicationStatusFilter] = useState<PublicationStatus>();
  const [refetchTrigger, setRefetchTrigger] = useState(0);

  const handleCertificationStatusChange = (certificationStatus: CertificationStatus) => {
    setSelectedCombo('all');
    setSelectedCertificationStatusFilter(certificationStatus);
  };

  const handlePublicationStatusChange = (publicationStatus: PublicationStatus) => {
    setSelectedCombo('all');
    setSelectedPublicationStatusFilter(publicationStatus);
  };

  const handleSelectedCombo = (id: ComboButtonId) => {
    setSelectedCombo(id);
    if(id === 'all') {
      setSelectedCertificationStatusFilter(undefined);
      setSelectedPublicationStatusFilter(undefined);
    }
    setRefetchTrigger((t) => t + 1);
  };

  return {
    selectedCombo,
    setSelectedCombo,
    selectedCertificationStatusFilter,
    setSelectedCertificationStatusFilter,
    selectedPublicationStatusFilter,
    setSelectedPublicationStatusFilter,
    handleCertificationStatusChange,
    handlePublicationStatusChange,
    handleSelectedCombo,
    refetchTrigger
  };
};
