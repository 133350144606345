import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Satisfaction } from '../../interfaces';
import { usePermissions } from '../../hooks/usePermissions';
import SatisfactionDetails from '../../components/satisfactions/detail/SatisfactionDetails';
import { mockSatisfactions } from './satisfactions';

function SatisfactionDetail() {
  const { satisfactionId } = useParams();
  const [satisfaction, setSatisfaction] = useState<Satisfaction | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'read');

      if (hasPermission) {
        satisfactionId && fetchSatisfaction(satisfactionId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [satisfactionId, permissionsLoaded]);

  const fetchSatisfaction = async (satisfactionId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/satisfactions/${satisfactionId}`);
      // const settingResponse = response.data;
      setSatisfaction(mockSatisfactions[0]);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = () => {
    setSatisfaction(null)
    satisfactionId && fetchSatisfaction(satisfactionId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <SatisfactionDetails satisfaction={satisfaction} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></SatisfactionDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default SatisfactionDetail;
