import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Button, } from 'react-bootstrap';
import {
  AttendanceStatus,
  PermissionsEnum,
} from '../../utils/enum';

import { useSortableData } from '../../hooks/useSortableData';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Attendance } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import AddEditAttendanceModal from '../../components/attendances/modal/AddEditAttendanceModal';
import { useSelection } from '../../hooks/useSelection';
import SearchInput from '../../components/SearchInput';
import AttendanceStatusDropdown from '../../components/AttendanceStatusDropdown';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

export const mockAttendances: Attendance[] = [
  {
    id: 1,
    title: 'Morning Jog',
    name: 'John Doe',
    email: 'john.doe@example.com',
    status: 1,
    notes: 'Jogged for 30 minutes'
  },
  {
    id: 2,
    title: 'Team Sync',
    name: 'Jane Smith',
    email: 'jane.smith@example.com',
    status: 1,
    notes: 'Discuss project updates'
  },
  {
    id: 3,
    title: 'Strategy Meeting',
    name: 'Mark Johnson',
    email: 'mark.johnson@example.com',
    status: 1,
    notes: 'Plan the next quarter strategy'
  },
  {
    id: 4,
    title: 'Code Debugging',
    name: 'Emily Davis',
    email: 'emily.davis@example.com',
    status: 1,
    notes: 'Debugged the login module'
  },
  {
    id: 5,
    title: 'Client Call',
    name: 'Michael Brown',
    email: 'michael.brown@example.com',
    status: 1,
    notes: 'Discuss client requirements'
  },
  {
    id: 6,
    title: 'Lunch with Team',
    name: 'Linda Wilson',
    email: 'linda.wilson@example.com',
    status: 1,
    notes: 'Team lunch at local restaurant'
  },
  {
    id: 7,
    title: 'Product Presentation',
    name: 'Kevin White',
    email: 'kevin.white@example.com',
    status: 1,
    notes: 'Present new product features'
  },
  {
    id: 8,
    title: 'UI/UX Workshop',
    name: 'Nancy Harris',
    email: 'nancy.harris@example.com',
    status: 1,
    notes: 'Workshop on UI/UX best practices'
  },
  {
    id: 9,
    title: 'Quarterly Review',
    name: 'Steven Moore',
    email: 'steven.moore@example.com',
    status: 1,
    notes: 'Review of quarterly performance'
  },
  {
    id: 10,
    title: 'Evening Yoga',
    name: 'Laura Taylor',
    email: 'laura.taylor@example.com',
    status: 0,
    notes: 'Yoga session for relaxation'
  }
];

interface AttendancesResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Attendance[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Attendances = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [attendances, setAttendances] = useState<Attendance[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedAttendances, requestSort } = useSortableData(attendances);

  const getId = useCallback((attendance: Attendance) => attendance.id, []);

  const {
    selectedItems: selectedAttendances,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(attendances, getId);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    }
  };

  const fetchAttendances = useCallback(async () => {
    setAttendances([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      // const response = await ApiClient.get(`/attendances${queryParams}`);
      // const attendancesResponse = response.data as AttendancesResponse
      // setTotalPages(attendancesResponse.amountPages);
      // setAttendances(attendancesResponse.list);
      // setCurrentPage(attendancesResponse.page);
      // setLimit(attendancesResponse.itemsPerPage);
      // setTotalEntries(attendancesResponse.amountAllItems);
      // setAvailableFilter(attendancesResponse.searchFilters)

      setAttendances(mockAttendances)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewAttendances, 'read');

      if (hasPermission) {
        fetchAttendances();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchAttendances,
    permissionsLoaded,
    location,
    searchQuery
  ]);


  const handleAttendanceUpdateSubmit = () => {
    fetchAttendances();
  };


  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  useEffect(() => {
    resetSearch();
  }, [location]);



  const handleAttendanceStatusUpdate = async (
    attendance: Attendance,
    newStatus: AttendanceStatus
  ) => {
    try {
      await ApiClient.put(`/attendances/${attendance.id}`, {
        status: newStatus,
      });

      await fetchAttendances();

      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Anwesenheiten</h3>
        <AddEditAttendanceModal
          buttonName="Anwesenheit hinzufügen"
          modalTitle={`Anwesenheit hinzufügen`}
          onSubmitSuccess={handleAttendanceUpdateSubmit}
        >
        </AddEditAttendanceModal>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={setSelectedCombo}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewAttendances, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewAttendances, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('email')}
              >
                E-Mail
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('status')}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedAttendances}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleAttendanceUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='attendances'
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedAttendances.map((attendance) => (
                <tr key={attendance.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewAttendances, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewAttendances, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedAttendances[attendance.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(attendance.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/attendances/${attendance.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {attendance.title}
                    </Link>
                  </td>
                  <td>{attendance.email}</td>
                  <td>
                    <AttendanceStatusDropdown
                      status={attendance.status}
                      handleStatusUpdate={(newStatus) => handleAttendanceStatusUpdate(attendance, newStatus)
                      }
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewAttendances, 'write') || attendance.status === 1}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedAttendances.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Attendances gefunden</p>
          </div>
        )}
      </div>

      {sortedAttendances.length > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Attendances;
