import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Dropdown, ButtonGroup, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CreateViewModal from './CreateViewModal';
import ViewRenameModal from './ViewRenameModal';
import ConfirmationModal from './ConfirmationModal';
import { faGlobe, faKey, faPlus, faSave, faTrash, faEdit, faClone, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { SortConfig } from '../hooks/useSortableData';
import { faLeftRight } from '@fortawesome/free-solid-svg-icons';
import PortalWrapper from './PortalWrapper';
import ShareLinkModal from './ShareLinkModal';
import { v4 as uuidv4 } from 'uuid';
import { ApiClient } from '../services/ApiClient';

export interface View {
    id?: number | null;
    uuid: string;
    title: string;
    filter: {};
    sort: SortConfig;
    columns: string[];
    limit: number;
    searchTerm: string;
    searchColumn: string;
    locked?: boolean;
    module?: string;
    created?: string;
    modified?: string;
    name?: string;
    user_id?: number;
}

interface ViewData {
    global: Record<string, View>;
    private: Record<string, View>;
    order: string[];
}

interface ViewSelectorProps {
    selectedColumns: string[];
    selectedFilters: any;
    selectedSortConfig: SortConfig;
    selectedLimit: number;
    selectedSearchTerm: string;
    selectedSearchColumn: string;
    entityType: string;
    onSelectionChange: (
        selectedColumns: string[],
        selectedFilters: any,
        selectedSortConfig: any,
        selectedLimit: number,
        selectedSearchTerm: string,
        selectedSearchColumn: string
    ) => void;
}

interface ComboButton {
    id: string;
    label: string;
    disabled?: boolean;
    disabledText?: string;
    error?: boolean;
}

// Renders a SortableItem, which represents each draggable view in the list
// It uses the DnD kit to handle dragging and dropping functionality.
const SortableItem: React.FC<{ id: string, label: string }> = ({ id, label }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString({
            ...transform,
            y: 0,
            scaleX: 1,
            scaleY: 1,
        } as any),
        transition,
        zIndex: isDragging ? 1000 : 'auto',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} className="d-flex align-items-center mb-2">
            <div  {...listeners} style={{ cursor: 'grab' }}>
                <FontAwesomeIcon icon={faLeftRight} className="ms-2 fs-13" />
            </div>

            <Button variant="link" className={`m-0 btn-sm text-start flex-grow-1 fs-13 ${isDragging && 'btn-soft-primary'}`}>
                {label}
            </Button>
        </div>
    );
};

// The ViewSelector component manages the views for selected columns, filters, sorting, etc.
// It also allows users to create, rename, delete, and toggle between global and private views.
const ViewSelector: React.FC<ViewSelectorProps> = ({ selectedColumns, onSelectionChange, selectedFilters, selectedSortConfig, selectedLimit, selectedSearchTerm, selectedSearchColumn, entityType }) => {
    const [selectedView, setSelectedView] = useState<string>('');
    const [columns, setColumns] = useState<string[]>([]);
    const [filters, setFilters] = useState<{}>({});
    const [sortConfig, setSortConfig] = useState<{}>({});
    const [limit, setLimit] = useState<number>(25);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchColumn, setSearchColumn] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
    const [confirmAction, setConfirmAction] = useState<() => void>(() => { });
    const [confirmTitle, setConfirmTitle] = useState<string>('');
    const [confirmMessage, setConfirmMessage] = useState<string>('');
    const [isGlobal, setIsGlobal] = useState<boolean>(false);
    const [initialColumns, setInitialColumns] = useState<string[]>(selectedColumns);
    const [initialFilters, setInitialFilters] = useState<{}>(selectedFilters);
    const [initialSortConfig, setInitialSortConfig] = useState<{}>(selectedSortConfig);
    const [initialLimit, setInitialLimit] = useState<number>(selectedLimit);
    const [initialSearchTerm, setInitialSearchTerm] = useState<string>(selectedSearchTerm);
    const [initialSearchColumn, setInitialSearchColumn] = useState<string>(selectedSearchColumn);
    const [dragging, setDragging] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<string | null>(null);
    const [currentGlobalViews, setCurrentGlobalViews] = useState<Record<string, boolean>>({});
    const [showAllButtons, setShowAllButtons] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [visibleCount, setVisibleCount] = useState<number>(0);
    const [viewData, setViewData] = useState<ViewData>({
        global: {},
        private: {},
        order: []
    });

    // Sorts the existing private and global views by the items in the order array
    const views = useMemo(() => {
        const allViews: Record<string, View> = { ...viewData.global, ...viewData.private };
        const orderedViews = viewData.order
            .filter(viewId => allViews.hasOwnProperty(viewId))
            .map(viewId => allViews[viewId]);

        const unorderedViews = Object.keys(allViews)
            .filter(viewId => !viewData.order.includes(viewId))
            .map(viewId => allViews[viewId]);

        return [...orderedViews, ...unorderedViews];
    }, [viewData]);

    const buttons: ComboButton[] = views.map((view: View) => ({
        id: view.uuid,
        label: view.title,
    }));

    // Save the updated view data by an API call.
    const saveViewData = async (viewData: ViewData) => {
        try {
            await ApiClient.post(`/me/views/${entityType}`, viewData);
        } catch (error) {
            console.error('Error saving view data:', error);
        }
    };

    // Get view data by an API call.
    const getViewData = async () => {
        try {
            const res = await ApiClient.get(`/me/views/${entityType}`);
            const parsedData = res.data
            const allViews = [...Object.keys(parsedData.global), ...Object.keys(parsedData.private)];
            const missingViews = allViews.filter(view => !parsedData.order.includes(view));
            if (missingViews.length > 0) {
                parsedData.order = [...parsedData.order, ...missingViews];
            }

            setViewData(parsedData as ViewData);
            setSelectedView(parsedData.order[0]); // Set the first view as selected
        } catch (error) {
            console.error('Error fetching view data:', error);
        }
    };

    useEffect(() => {
        getViewData();
    }, []);

    // When a new view is selected, it updates the state and passes changes to the parent component.
    // It also checks if there are any differences between the current and selected view.
    useEffect(() => {
        const selectedViewData = views.find(view => view.uuid === selectedView);
        if (selectedViewData) {
            const newColumns = selectedViewData.columns;
            const newFilters = selectedViewData.filter;
            const newSortConfig = selectedViewData.sort;
            const newLimit = selectedViewData.limit;
            const newSearchTerm = selectedViewData.searchTerm;
            const newSearchColumn = selectedViewData.searchColumn;

            if (
                columns.join() !== newColumns.join() ||
                JSON.stringify(filters) !== JSON.stringify(newFilters) ||
                JSON.stringify(sortConfig) !== JSON.stringify(newSortConfig) ||
                limit !== newLimit ||
                searchTerm !== newSearchTerm ||
                searchColumn !== newSearchColumn
            ) {
                setColumns(newColumns);
                setFilters(newFilters);
                setSortConfig(newSortConfig);
                setLimit(newLimit);
                setSearchTerm(newSearchTerm);
                setSearchColumn(newSearchColumn);

                setInitialColumns(newColumns);
                setInitialFilters(newFilters);
                setInitialSortConfig(newSortConfig);
                setInitialLimit(newLimit);
                setInitialSearchTerm(newSearchTerm);
                setInitialSearchColumn(newSearchColumn);

                onSelectionChange(newColumns, newFilters, newSortConfig, newLimit, newSearchTerm, newSearchColumn);
            }
        }
    }, [selectedView, onSelectionChange, columns, filters, sortConfig, limit, searchTerm, searchColumn, views]);

    // Updates global/private view status based on the viewData whenever viewData changes.
    useEffect(() => {
        const globalViews: Record<string, boolean> = {};
        Object.keys(viewData.global).forEach(viewId => {
            globalViews[viewId] = true;
        });
        Object.keys(viewData.private).forEach(viewId => {
            globalViews[viewId] = false;
        });
        setCurrentGlobalViews(globalViews);
    }, [viewData]);

    // Calculates the number of visible buttons based on the available container space.
    useEffect(() => {
        calculateVisibleButtons();
        window.addEventListener('resize', calculateVisibleButtons);
        return () => window.removeEventListener('resize', calculateVisibleButtons);
    }, [buttons]);

    // Smoothly scrolls to reveal more buttons when the "show all" option is clicked.
    useEffect(() => {
        if (showAllButtons && containerRef.current) {
            containerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
        }
    }, [showAllButtons]);

    // Calculates the amount of visible buttons based on the container's width.
    const calculateVisibleButtons = useCallback(() => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            let totalWidth = 0;
            let count = 0;

            Array.from(containerRef.current.children).forEach((child) => {
                const width = (child as HTMLElement).offsetWidth * 1.2;
                totalWidth += width;
                if (totalWidth <= containerWidth) count++;
            });
            setVisibleCount(count);
        }
    }, [visibleCount]);

    // Handles changing the selected view. If the same view is clicked, it toggles the dropdown.
    const handleChangeSelectedView = (id: string) => {
        if (selectedView !== id) {
            setSelectedView(id);
            setShowDropdown(null);
        } else {
            setShowDropdown(prev => (prev === id ? null : id));
        }
    };

    // Handles saving the selected columns, filters, sorting, etc., to a new view in the view list.
    const handleModalSave = (viewName: string, isGlobal: boolean) => {
        createNewView(viewName, selectedColumns, selectedFilters, selectedSortConfig, selectedLimit, selectedSearchTerm, selectedSearchColumn, isGlobal);
    };

    // Creates a new view based on the provided settings (columns, filters, sorting) and adds it to global or private views.
    const createNewView = (
        viewName: string,
        columns: string[],
        filter: any,
        sort: any,
        limit: number,
        searchTerm: string,
        searchColumn: string,
        isGlobal: boolean
    ) => {
        const viewId = uuidv4();
        const newView = {
            uuid: viewId,
            title: viewName,
            filter,
            columns,
            sort,
            limit,
            searchTerm,
            searchColumn,
        };
        const newData = isGlobal ? {
            ...viewData,
            global: {
                ...viewData.global,
                [viewId]: newView,
            },
            order: [viewId, ...viewData.order]
        } : {
            ...viewData,
            private: {
                ...viewData.private,
                [viewId]: newView
            },
            order: [viewId, ...viewData.order]
        };

        setViewData(newData);
        saveViewData(newData);
        setSelectedView(viewId);
    };

    // Handles the creation of a new view, toggling between global and private options.
    const handleCreateView = (isGlobal: boolean) => {
        setIsGlobal(isGlobal);
        setShowModal(true);
    };

    // Toggles whether a view is global or private by transferring it between the global and private lists.
    const handleToggleGlobalView = (viewId: string, e: React.MouseEvent<HTMLInputElement>) => {
        setViewData(prevData => {
            const newData = { ...prevData };
            if (newData.global[viewId]) {
                newData.private[viewId] = newData.global[viewId];
                delete newData.global[viewId];
            } else {
                newData.global[viewId] = newData.private[viewId];
                delete newData.private[viewId];
            }
            saveViewData(newData);
            return newData;
        });

        setCurrentGlobalViews(prev => ({
            ...prev,
            [viewId]: !prev[viewId]
        }));
    };

    // Saves any changes made to the current view configuration (columns, filters, sorting, etc.) back to the view data.
    const handleSaveChanges = () => {
        setViewData(prevData => {
            const newData = { ...prevData };
            if (currentGlobalViews[selectedView]) {
                newData.global[selectedView].columns = [...selectedColumns];
                newData.global[selectedView].filter = { ...selectedFilters };
                newData.global[selectedView].sort = { ...selectedSortConfig };
                newData.global[selectedView].limit = selectedLimit;
                newData.global[selectedView].searchTerm = selectedSearchTerm;
                newData.global[selectedView].searchColumn = selectedSearchColumn;
            } else {
                newData.private[selectedView].columns = [...selectedColumns];
                newData.private[selectedView].filter = { ...selectedFilters };
                newData.private[selectedView].sort = { ...selectedSortConfig };
                newData.private[selectedView].limit = selectedLimit;
                newData.private[selectedView].searchTerm = selectedSearchTerm;
                newData.private[selectedView].searchColumn = selectedSearchColumn;
            }
            saveViewData(newData);
            return newData;
        });

        setInitialColumns([...selectedColumns]);
        setInitialFilters({ ...selectedFilters });
        setInitialSortConfig({ ...selectedSortConfig });
        setInitialLimit(selectedLimit);
        setInitialSearchTerm(selectedSearchTerm);
        setInitialSearchColumn(selectedSearchColumn);
    };

    // Renames a view and updates the title in the view data.
    const handleRenameView = (newName: string) => {
        setViewData(prevData => {
            const newData = { ...prevData };
            if (currentGlobalViews[selectedView]) {
                newData.global[selectedView].title = newName;
            } else {
                newData.private[selectedView].title = newName;
            }
            saveViewData(newData);
            return newData;
        });
    };

    // Deletes the currently selected view and removes it from viewData.
    const handleDeleteView = () => {
        setViewData(prevData => {
            const newData = { ...prevData };
            if (currentGlobalViews[selectedView]) {
                delete newData.global[selectedView];
            } else {
                delete newData.private[selectedView];
            }
            newData.order = newData.order.filter(viewId => viewId !== selectedView);
            setSelectedView(newData.order[0]);
            saveViewData(newData);
            return newData;
        });
    };

    // Duplicates the currently selected view, creating a copy with the same configuration but a new ID and title.
    const handleDuplicateView = () => {
        const viewId = uuidv4();
        const originalView = currentGlobalViews[selectedView] ? viewData.global[selectedView] : viewData.private[selectedView];
        const newName = `${originalView.title}-2`;
        const newView = {
            ...originalView,
            id: null,
            uuid: viewId,
            title: newName
        };
        const newData = currentGlobalViews[selectedView] ? {
            ...viewData,
            global: {
                ...viewData.global,
                [viewId]: newView
            },
            order: [...viewData.order, viewId]
        } : {
            ...viewData,
            private: {
                ...viewData.private,
                [viewId]: newView
            },
            order: [...viewData.order, viewId]
        };

        setViewData(newData);
        saveViewData(newData);
        setSelectedView(viewId);
    };

    // Displays a confirmation modal for actions like deleting a view or other irreversible changes.
    const showConfirmation = (action: () => void, title: string, message: string) => {
        setConfirmAction(() => action);
        setConfirmTitle(title);
        setConfirmMessage(message);
        setShowConfirmModal(true);
    };

    // Renders the dropdown menu for view management, allowing actions like saving changes, renaming, duplicating, and deleting views.
    const renderDropdownMenu = (id: string) => {
        if (selectedView !== id) return null;

        const isGlobalView = currentGlobalViews[id];
        const hasChanges = JSON.stringify(initialColumns) !== JSON.stringify(selectedColumns) ||
            JSON.stringify(initialFilters) !== JSON.stringify(selectedFilters) ||
            JSON.stringify(initialSortConfig) !== JSON.stringify(selectedSortConfig) ||
            initialLimit !== selectedLimit ||
            initialSearchTerm !== selectedSearchTerm ||
            initialSearchColumn !== selectedSearchColumn;

        return (
            <PortalWrapper>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={(e) => { e.preventDefault(); handleSaveChanges(); }} disabled={!hasChanges}>
                        <FontAwesomeIcon className='me-1 text-primary' width={15} icon={faSave} /> Anpassungen speichern
                    </Dropdown.Item>

                    <div className="horizontal-line my-1"></div>
                    <Dropdown.Item className='cursor-pointer' onClick={(e) => { e.preventDefault(); }}>
                        <Form.Check
                            className='pe-0 me-0 checkbox-pointer'
                            type="checkbox"
                            label="Globale Ansicht"
                            checked={isGlobalView}
                            onClick={(e) => handleToggleGlobalView(id, e)}
                            onChange={() => { }}
                            disabled={isGlobalView ? Object.keys(viewData.private).length >= 5 : Object.keys(viewData.global).length >= 5}
                        />
                    </Dropdown.Item>

                    <div className="horizontal-line my-1"></div>
                    <Dropdown.Item disabled={isGlobalView ? Object.keys(viewData.global).length >= 5 : Object.keys(viewData.private).length >= 5} onClick={handleDuplicateView}>
                        <FontAwesomeIcon className='me-1 text-primary' width={15} icon={faClone} /> Ansicht duplizieren
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowRenameModal(true)}>
                        <FontAwesomeIcon className='me-1 text-primary' width={15} icon={faEdit} /> Ansicht umbenennen
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setDragging(true)}>
                        <FontAwesomeIcon className='me-1 text-primary' width={15} icon={faClone} /> Verschieben
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => showConfirmation(handleDeleteView, 'Ansicht löschen', 'Möchten Sie diese Ansicht wirklich löschen?')}>
                        <FontAwesomeIcon className='me-1 text-danger' width={15} icon={faTrash} /> Ansicht löschen
                    </Dropdown.Item>
                </Dropdown.Menu>
            </PortalWrapper>
        );
    };

    // Renders individual buttons for each view in the list, displaying whether the view is global or private.
    const renderButton = ({ id, label, disabled, error }: ComboButton) => {
        const hasChanges = selectedView === id && (
            JSON.stringify(initialColumns) !== JSON.stringify(selectedColumns) ||
            JSON.stringify(initialFilters) !== JSON.stringify(selectedFilters) ||
            JSON.stringify(initialSortConfig) !== JSON.stringify(selectedSortConfig) ||
            initialLimit !== selectedLimit ||
            initialSearchTerm !== selectedSearchTerm ||
            initialSearchColumn !== selectedSearchColumn
        );
        const isGlobalView = currentGlobalViews[id];

        return (
            <div key={id} className="d-flex align-items-center">
                <Dropdown show={selectedView === id && showDropdown === id} onToggle={() => handleChangeSelectedView(id)} drop='down' as={ButtonGroup}>
                    <Button
                        variant="link"
                        className={`btn ${selectedView === id ? 'btn-soft-primary' : ''} btn-sm rounded ${error ? 'text-danger' : ''} fs-13`}
                        htmlFor={id}
                        as="label"
                        onClick={() => handleChangeSelectedView(id)}
                    >
                        <div className='d-flex align-items-center justify-content-center w-100'>
                            <FontAwesomeIcon size='xs' icon={isGlobalView ? faGlobe : faKey} className='me-2' />
                            <div>{label}{hasChanges && viewData.global[selectedView]?.locked !== true && '*'}</div>
                            {selectedView === id && viewData.global[selectedView]?.locked !== true && (
                                <Dropdown.Toggle
                                    split
                                    className='p-0 ms-1 dropdown-toggle-no-hover'
                                    variant="link"
                                    id={`dropdown-split-${id}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeSelectedView(id);
                                    }}
                                />
                            )}
                        </div>
                    </Button>
                    {renderDropdownMenu(id)}
                </Dropdown>
            </div>
        );
    };

    // Renders all sortable items for views when drag-and-drop mode is active.
    const renderSortableItems = () => {
        return buttons.map(button => (
            <SortableItem key={button.id} id={button.id} label={button.label} />
        ));
    };

    // Handles the end of a drag event, updating the view order based on the new positions.
    const handleDragEnd = ({ active, over }: any) => {
        if (!over || active.id === over.id) {
            setDragging(false);
            return;
        }

        setViewData(prevData => {
            const newOrder = arrayMove(prevData.order, prevData.order.indexOf(active.id), prevData.order.indexOf(over.id));
            const newData = { ...prevData, order: newOrder };
            saveViewData(newData);
            return newData;
        });

        setDragging(false);
        setSelectedView(active.id);
    };

    return (
        <div ref={containerRef} className="overflow-container mb-3 custom-scrollbar-x">
            {!dragging && (
                <div className="sticky-left d-flex">
                    {/* <div className='d-flex justify-content-center align-items-center'>
                        <FontAwesomeIcon
                            size="lg"
                            icon={faCopy}
                            className="text-primary cursor-pointer me-2"
                            onClick={() => setShowShareLinkModal(true)}
                        />
                    </div> */}

                    <Dropdown>
                        <Dropdown.Toggle as="button" className="btn btn-link text-primary no-caret ps-0 pe-3 border-none">
                            <FontAwesomeIcon size="lg" icon={faPlus} />
                        </Dropdown.Toggle>

                        <PortalWrapper>
                            <Dropdown.Menu>
                                <Dropdown.Item disabled={Object.keys(viewData.private).length === 5} onClick={() => handleCreateView(false)}>
                                    <FontAwesomeIcon width={15} icon={faKey} /> Private Ansicht ({Object.keys(viewData.private).length}/5)
                                </Dropdown.Item>
                                <Dropdown.Item disabled={Object.keys(viewData.global).length === 5} onClick={() => handleCreateView(true)}>
                                    <FontAwesomeIcon width={15} icon={faGlobe} /> Globale Ansicht ({Object.keys(viewData.global).length}/5)
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </PortalWrapper>
                    </Dropdown>
                </div>
            )}

            {dragging ? (
                <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={buttons.map(button => button.id)}>
                        {renderSortableItems()}
                    </SortableContext>
                </DndContext>
            ) : (
                <>
                    {buttons.slice(0, visibleCount).map(button => (
                        <div key={button.id} className='d-inline-block'>
                            {renderButton(button)}
                        </div>
                    ))}
                    {visibleCount < buttons.length && !showAllButtons && (
                        <button
                            className="text-center btn btn-link text-primary p-0 m-0 me-3 border-none"
                            onClick={() => {
                                setShowAllButtons(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faEllipsis} />
                        </button>
                    )}
                    {showAllButtons && buttons.slice(visibleCount).map(button => (
                        <div key={button.id} className='d-inline-block'>
                            {renderButton(button)}
                        </div>
                    ))}
                </>
            )}

            <CreateViewModal show={showModal} handleClose={() => setShowModal(false)} handleSave={handleModalSave} isGlobal={isGlobal} />
            <ViewRenameModal
                show={showRenameModal}
                handleClose={() => setShowRenameModal(false)}
                handleConfirm={handleRenameView}
                currentName={views.find(view => view.uuid === selectedView)?.title || ''}
            />
            <ConfirmationModal
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={() => {
                    confirmAction();
                    setShowConfirmModal(false);
                }}
                title={confirmTitle}
                message={confirmMessage}
            />
            <ShareLinkModal
                show={showShareLinkModal}
                handleClose={() => setShowShareLinkModal(false)}
                currentView={{
                    columns: [...selectedColumns],
                    filter: { ...selectedFilters },
                    sortConfig: { ...selectedSortConfig },
                    limit: selectedLimit,
                    searchTerm: selectedSearchTerm,
                    searchColumn: selectedSearchColumn
                } as unknown as View}
            />
        </div>
    );
};

export default ViewSelector;
