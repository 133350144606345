import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Dropdown } from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';

import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../hooks/useSortableData';
import { useSelection } from '../../hooks/useSelection';

import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';

import SelectionActions from '../../components/media/SelectionActions';
import { useNavigate, useParams } from 'react-router-dom';

import { MediaItem, MediaItemWithProgress } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import UploadMediaModal from '../../components/media/modal/UploadMediaModal';
import MediaDetails from '../../components/media/MediaDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { faDownload, } from '@fortawesome/pro-regular-svg-icons';
import NewFolderModal from '../../components/media/modal/NewFolderModal';
import { formatSizeUnits, getMediaIcon, getMediaStatusIcons, getMediaUrl, isMediaSecure } from '../../utils/media/utils';
import Breadcrumbs from '../../components/media/Breadcrumbs';
import MediumEditModal from '../../components/media/modal/MediumEditModal';
import PortalWrapper from '../../components/PortalWrapper';
import TableWithFileDropzone from '../../components/media/TableWithFileDropzone';
import TemporaryFiles from '../../components/table/temporaryFileRows';
import DeleteModal from '../../components/DeleteModal';

interface MediaResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: MediaItem[];
}

const Media = memo(() => {
  const navigate = useNavigate();
  const { companyId = 'oc', folderId = 0, mediumId } = useParams();
  const [selectedFolderId, setSelectedFolderId] = useState(folderId)
  const [temporaryFiles, setTemporaryFiles] = useState<MediaItemWithProgress[]>([]);

  const { show, message, error, showToast, hideToast } = useToast();
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [selectedMediaForDetailView, setSelectedMediaForDetailView] = useState<MediaItem | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedMedia, requestSort } = useSortableData(media);
  const [breadcrumbs, setBreadcrumbs] = useState<MediaItem[]>([]);

  const handleBreadcrumbs = (newBreadcrumbs: MediaItem[]) => {
    setBreadcrumbs(newBreadcrumbs);
  };


  const getId = useCallback((media: MediaItem) => media.id, []);

  const {
    selectedItems: selectedMedia,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(media, getId);

  const fetchMedia = useCallback(async () => {
    setMedia([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}&folderId=${selectedFolderId}`;

    if (searchQuery) {
      queryParams += `&search=${encodeURIComponent(searchQuery)}`;
    }

    try {
      const response = await ApiClient.get(`/media${queryParams}`);
      const mediaResponse = response.data as MediaResponse;
      setTotalPages(mediaResponse.amountPages);
      setMedia(mediaResponse.list ?? []);
      setCurrentPage(mediaResponse.page);
      setLimit(mediaResponse.itemsPerPage);
      setTotalEntries(mediaResponse.amountAllItems);
      setIsLoading(false);

      if (mediumId) {
        setSelectedMediaForDetailView(null)
        const res = await ApiClient.get(`/media/${mediumId}`);
        setSelectedMediaForDetailView(res.data)
        navigateIfDifferent(`/${companyId}/media/${selectedFolderId}/${mediumId}`)
      }

    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery, selectedFolderId]);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewMedia, 'read');

      if (hasPermission) {
        fetchMedia();
      } else {
        navigateIfDifferent('/errors/error404')
      }
    }
  }, [
    currentPage,
    searchQuery,
    permissionsLoaded,
    selectedFolderId
  ]);

  useEffect(() => {
    if (folderId !== selectedFolderId) {
      setSelectedFolderId(folderId);
    }
  }, [folderId, selectedFolderId]);

  const handleNewFolderSubmit = (mediaId: number | null, message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    navigateIfDifferent(`/${companyId}/media/${mediaId}`)
  };

  const handleMediaUpdateSubmit = (message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    fetchMedia();
  };

  const handleMediumOrFolderClick = async (media: MediaItem) => {
    if (media.mediaType === 'inode') {
      setSelectedMediaForDetailView(null)
      setCurrentPage(1)
      navigateIfDifferent(`/${companyId}/media/${media.id}`)
    } else {
      navigateIfDifferent(`/${companyId}/media/${folderId}/${media.id}`, true)
      setSelectedMediaForDetailView(null)
      setSelectedMediaForDetailView(media)
    }
  }

  const handlePreviewCloseClick = () => {
    setSelectedMediaForDetailView(null)
    navigateIfDifferent(`/${companyId}/media/${selectedFolderId}`)
  }


  useEffect(() => {
    const activeKeys = Object.keys(selectedMedia)
    if (activeKeys.length > 1) {
      handlePreviewCloseClick()
    }
  }, [selectedMedia]);


  const navigateIfDifferent = (targetPath: string, replace: boolean = false): void => {
    if (window.location.pathname !== targetPath) {
      navigate(targetPath, { replace });
    }
  };


  const handleFilesChange = async (newFiles: File[]) => {

    if (!newFiles.length) {
      return
    }

    const tempFiles = newFiles.map((file, index) => ({
      id: `temp-${index}`,
      title: file.name,
      size: file.size,
      mediaType: 'file',
      progress: 0,
    }));

    setTemporaryFiles(tempFiles as any);

    try {
      for (let index = 0; index < newFiles.length; index++) {
        const file = newFiles[index];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folderId', selectedFolderId?.toString() ? selectedFolderId.toString() : folderId.toString());

        await ApiClient.post('/upload', formData, {
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total ? progressEvent.total : progressEvent.loaded;
            const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
            setTemporaryFiles(prevFiles => {
              const newFiles = [...prevFiles];
              newFiles[index].progress = percentCompleted;
              return newFiles;
            });
          }
        });
      }
      showToast(`Erfolgreich gespeichert`, false);
      await fetchMedia();
    } catch (error) {
      showToast('Fehler beim Speichern', true);
      console.error('Error during file upload:', error);
    } finally {
      setTemporaryFiles([]);
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Medien</h3>
        <div className="d-flex">
          <NewFolderModal
            modalTitle="Neuer Ordner"
            buttonName="Neuer Ordner"
            onSubmitSuccess={handleNewFolderSubmit}
            onFolderModalChange={() => { }}>
          </NewFolderModal>

          <UploadMediaModal
            modalTitle="Hochladen"
            buttonName="Hochladen"
            onSubmitSuccess={handleMediaUpdateSubmit}
            onUploadMediaModalChange={() => { }}
          ></UploadMediaModal>

        </div>
      </div>

      <Row>
        <Col md={selectedMediaForDetailView ? 9 : 12}>
          <Card className="card-block card-stretch">
            <Card.Body>
              <Row className="d-flex justify-content-between">
                <Col md={6}>
                  <Breadcrumbs onBreadcrumbsChange={handleBreadcrumbs} currentFolderId={Number(selectedFolderId)}></Breadcrumbs>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <TableWithFileDropzone onFilesChange={handleFilesChange}>
            <div style={{ overflowX: 'auto' }}>
              <Table responsive="md" size="sm">
                <thead>
                  <tr>
                    <th
                      className="d-flex align-items-center"
                      style={{ cursor: 'pointer' }}
                      scope="col"
                      onClick={() => requestSort('title')}
                    >
                      <div className='d-flex'>
                        <label className="d-flex align-items-center w-100 ps-2">
                          <Form.Check
                            disabled={!userHasPermissionByRight(PermissionsEnum.ViewMedia, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewMedia, 'delete')}
                            className="me-3"
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          />

                        </label>
                        Name
                      </div>
                    </th>
                    <th
                      style={{ cursor: 'pointer', width: '80px' }}
                      scope="col"
                      onClick={() => requestSort('size')}
                    >
                      Dateigröße
                    </th>
                    <th
                      style={{ cursor: 'pointer', width: '80px' }}
                      scope="col"
                    >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCount > 0 && (
                    <SelectionActions
                      media={sortedMedia}
                      selectedMedia={selectedMedia}
                      selectedCount={selectedCount}
                      handleDeSelectAll={handleDeSelectAll}
                      onSubmitSuccess={handleMediaUpdateSubmit}
                    ></SelectionActions>
                  )}
                  {temporaryFiles.length > 0 ? (
                    <TemporaryFiles temporaryFiles={temporaryFiles} />
                  ) : (
                    isLoading
                      ? Array.from({ length: 8 }).map((_, index) => (
                        <SkeletonRow key={index} columnCount={3} />
                      ))
                      : sortedMedia.map((media) => (
                        <tr key={media.id} className={`${selectedMediaForDetailView?.id === media.id ? 'bg-light-blue' : 'bg-white'} cursor-pointer`}>
                          <td onClick={() => handleMediumOrFolderClick(media)} >
                            <div className="d-flex align-items-center text-black">
                              <div className="d-flex align-items-center">
                                <label className="d-flex align-items-center w-100 ps-2" onClick={(event) => event.stopPropagation()}>
                                  <Form.Check
                                    disabled={!userHasPermissionByRight(PermissionsEnum.ViewMedia, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewMedia, 'delete')}
                                    className="me-3"
                                    type="checkbox"
                                    checked={selectedMedia[media.id] ?? false}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSelectRow(media.id, e)
                                    }}
                                    onChange={() => { }}
                                  />
                                </label>
                                {getMediaIcon(media)} {getMediaStatusIcons(media)} {media.title}
                              </div>
                            </div>
                          </td>
                          <td>{media.size ? formatSizeUnits(media.size) : '-'}</td>
                          <td>
                            <Dropdown className="me-2">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                              >
                                <FontAwesomeIcon className='text-black' size='lg' icon={faEllipsisVertical} />
                              </Dropdown.Toggle>
                              <PortalWrapper>
                                <Dropdown.Menu>

                                  {media.mediaType !== 'inode' &&
                                    <a href={getMediaUrl(media)} target="_blank" rel="noopener noreferrer">
                                      <Dropdown.Item as="div">
                                        <FontAwesomeIcon width={30} icon={faDownload} />
                                        Herunterladen
                                      </Dropdown.Item>
                                    </a>
                                  }

                                  <MediumEditModal
                                    modalTitle={`${media.mediaType === 'inode' ? 'Ordner' : "Datei"} bearbeiten`}
                                    buttonName="Bearbeiten"
                                    media={media}
                                    onSubmit={handleMediaUpdateSubmit}
                                    isDropdownItem={true}>
                                  </MediumEditModal>

                                  {!isMediaSecure(media) && <DeleteModal
                                    modalTitle="Löschen?"
                                    buttonName="Löschen"
                                    entityIds={[media.id]}
                                    entityType='media'
                                    onSubmit={handleMediaUpdateSubmit}
                                    isDropdownItem={true}>
                                  </DeleteModal>}
                                </Dropdown.Menu>
                              </PortalWrapper>
                            </Dropdown>
                          </td>
                        </tr>
                      )))}
                </tbody>
              </Table>
              {!isLoading && sortedMedia.length === 0 && (
                <div
                  className="d-flex justify-content-center align-items-center border rounded my-3"
                  style={{ height: '50px' }}
                >
                  <p className="p-0 m-0">Keine Medien in Ordner <b>{breadcrumbs[breadcrumbs.length - 1].title}</b> gefunden</p>
                </div>
              )}
            </div>
          </TableWithFileDropzone>

          {sortedMedia.length > 0 && (
            <Row>
              <Col>
                <PaginationInfo
                  currentPage={currentPage}
                  limit={limit}
                  totalEntries={totalEntries}
                  onLimitChange={(size) => {
                    setLimit(size);
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col className="d-flex justify-content-end">
                <DynamicPagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </Col>
            </Row>
          )}</Col>
        <Col md={selectedMediaForDetailView ? 3 : 0}>
          {selectedMediaForDetailView && <MediaDetails media={selectedMediaForDetailView} onClose={handlePreviewCloseClick}></MediaDetails>}
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Media;
