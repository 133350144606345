import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface SaveCancelPopupProps {
  onSave: () => void;
  onAbort: () => void;
  pendingChangesCount: number;
}

// A popup component that provides 'Save' and 'Cancel' buttons for saving or discarding pending changes.
const SaveCancelPopup: React.FC<SaveCancelPopupProps> = ({ onSave, onAbort, pendingChangesCount }) => {
  const [isSaving, setIsSaving] = useState(false);

  // Handles the 'Save' button click, triggers the onSave function, and shows a spinner while saving.
  const handleSaveClick = async () => {
    setIsSaving(true);
    try {
      await onSave();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="save-cancel-popup w-100 d-flex align-items-center">
      <Button className="btn btn-soft-primary me-2" onClick={handleSaveClick} disabled={isSaving}>
        {isSaving ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
            Speichern...
          </>
        ) : (
          'Speichern'
        )}
      </Button>
      <Button variant="muted" onClick={onAbort} disabled={isSaving}>
        Abbrechen
      </Button>

      <div className="ms-3">
        {pendingChangesCount} ungespeicherte Änderung{pendingChangesCount > 1 && 'en'}
      </div>
    </div>
  );
};

export default SaveCancelPopup;
