import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationModalProps {
    show: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    title: string;
    message: string;
}

// A reusable confirmation modal component that prompts users to confirm or cancel an action.
// It takes in a title and a message to display within the modal, and two handler functions:
// - handleClose: to execute when the user cancels or closes the modal.
// - handleConfirm: to execute when the user confirms the action.
// The modal is displayed based on the 'show' prop, which controls its visibility.
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, handleClose, handleConfirm, title, message }) => (
    <Modal
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
        show={show} onHide={handleClose}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><p>{message}</p></Modal.Body>
        <Modal.Footer>
            <Button className="btn-soft-primary" onClick={handleClose}>
                Abbrechen
            </Button>
            <Button variant="danger" onClick={handleConfirm}>
                Bestätigen
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;
