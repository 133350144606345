import { useNavigate, useParams } from 'react-router-dom';
import { NavigateFunction, To, NavigateOptions } from 'react-router-dom';

function useCompanyNavigate(defaultCompanyId: string = 'oc'): (to: To, options?: NavigateOptions | undefined) => void {
  const navigate: NavigateFunction = useNavigate();
  const { companyId = defaultCompanyId } = useParams<{ companyId?: string }>();

  const companyNavigate = (to: To, options?: NavigateOptions): void => {
    navigate(`/${companyId}${to}`, options);
  };

  return companyNavigate;
}

export default useCompanyNavigate;