import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import PortalWrapper from '../PortalWrapper';
import { FieldConfig } from '../../interfaces';
import SelectWithSearch, { SelectOption } from '../form/SelectWithSearch';
import { ApiClient } from '../../services/ApiClient';
import { formatDate, getFieldConfigByResourceName } from '../../utils/utils';

type DefaultColumnRenderProps<T> = {
    item: T;
    columnKey: string;
    editableCell: { rowId: number | null; columnKey: string | null };
    handleCellClick: (rowId: number | null, columnKey: string | null) => void;
    handleFieldChange: (rowId: number, columnKey: string, value: any) => void;
    handleRevertChange: () => void;
    fieldConfigs: FieldConfig[];
    pendingChanges: { [key: string]: any };
    activeTooltip: string | null;
    setActiveTooltip: (tooltipId: string | null) => void;
};

// Renders a table cell with the ability to edit its contents when clicked.
// Supports multiple field types (text, textarea, select, etc.) and tracks pending changes.
const DefaultColumnRender = <T extends { [key: string]: any }>({
    item,
    columnKey,
    editableCell,
    handleCellClick,
    handleFieldChange,
    handleRevertChange,
    fieldConfigs,
    pendingChanges,
    activeTooltip,
    setActiveTooltip,
}: DefaultColumnRenderProps<T>) => {
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    // Get the fieldConfig that matches the resourceName (columnKey)
    const fieldConfig = getFieldConfigByResourceName(fieldConfigs, columnKey);

    const fieldValue = getFieldValue(item, columnKey, pendingChanges);
    const isEditable = editableCell.rowId === item.id && editableCell.columnKey === columnKey;
    const pendingChange = pendingChanges[`${item.id}-${columnKey}`];
    const [fetchedOptions, setFetchedOptions] = useState<{ [key: string]: string } | null>(null);
    const [localValue, setLocalValue] = useState(fieldValue);
    const [showOverlay, setShowOverlay] = useState(false);
    const [overlayPosition, setOverlayPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

    const triggerRef = useRef<HTMLDivElement | null>(null);

    // Handles changes for text, select, textarea, and other field types.
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const parsedValue = e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value;
        setLocalValue(parsedValue);
    };

    // Handles selection changes for dropdown fields (select with search).
    const handleSelectChange = (id: string, selectedOption: SelectOption | null) => {
        setLocalValue(selectedOption);
    };

    // Saves the updated field value to the parent component.
    const handleSaveClick = () => {
        const currentValue = pendingChanges[`${item.id}-${columnKey}`]?.value || item[columnKey];
        if (currentValue !== localValue) {
            handleFieldChange(item.id, columnKey, localValue);
        }
        setShowOverlay(false);
        setActiveTooltip(null);
    };

    // Aborts the changes and reverts the field value to its original state.
    const handleAbortClick = () => {
        handleRevertChange();
        setLocalValue(fieldValue);
        setShowOverlay(false);
        setActiveTooltip(null);
        handleCellClick(null, null);
    };

    // Updates the local value whenever the field value or pending changes are updated.
    useEffect(() => {
        setLocalValue(fieldValue);
    }, [fieldValue]);

    // Calculates the position for the quick edit overlay when the cell is clicked.
    const calculatePosition = () => {
        if (!triggerRef.current) return { top: 0, left: 0 };
        const rect = triggerRef.current.getBoundingClientRect();
        return {
            top: rect.bottom + window.scrollY + 10,
            left: rect.left + window.scrollX,
        };
    };

    // Get the field value from pending changes (if applicable), the item, or the lastDeal. If the value is a date, it will be formatted.
    function getFieldValue(item: any, columnKey: string, pendingChanges: { [key: string]: any }): any {
        const pendingLabel = pendingChanges?.[`${item.id}-${columnKey}`]?.value?.label;
        const pendingValue = pendingChanges?.[`${item.id}-${columnKey}`]?.value;
        const [module, fieldKey] = columnKey.split('.');

        let fieldValue;

        if (module === 'lastDeal') {
            fieldValue = item?.lastDeal?.[fieldKey];
        } else {
            fieldValue = item?.[fieldKey];
        }

        // If there are pending changes, they take precedence
        if (pendingLabel !== undefined && pendingLabel !== null) {
            fieldValue = pendingLabel;
        } else if (pendingValue !== undefined && pendingValue !== null) {
            fieldValue = pendingValue;
        }

        // Regular expression to match date-time format: YYYY-MM-DD HH:MM:SS
        const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

        // If the fieldValue is a string and matches the date-time format, format it
        if (typeof fieldValue === 'string' && dateTimeRegex.test(fieldValue)) {
            const date = new Date(fieldValue);
            if (!isNaN(date.getTime())) {
                fieldValue = formatDate(fieldValue, 'd.m.Y'); // Format the date
            }
        }
        return fieldValue;
    }

    // Sets up event listeners for resize, scroll, and clicking outside the overlay when it's open.
    useEffect(() => {
        if (showOverlay) {
            const position = calculatePosition();
            setOverlayPosition(position);

            const handleResizeOrScroll = () => {
                const newPosition = calculatePosition();
                setOverlayPosition(newPosition);
            };

            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleAbortClick();
                }
            };

            window.addEventListener('resize', handleResizeOrScroll);
            const tableContainer = document.querySelector('#scroll-container');
            tableContainer?.addEventListener('scroll', handleResizeOrScroll);

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('resize', handleResizeOrScroll);
                tableContainer?.removeEventListener('scroll', handleResizeOrScroll);
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [showOverlay]);

    useEffect(() => {
        const fetchSettings = async (url: string) => {
            const res = await ApiClient.get(url);
            setFetchedOptions(res.data.list[0].details || {});
        };

        if (showOverlay && fieldConfig?.optionsprovider?.includes('settings')) {
            fetchSettings(`${fieldConfig.optionsprovider}`);
        }
    }, [showOverlay, fieldConfig?.optionsprovider]);

    // Handles the logic when a cell is clicked to either open or close the edit overlay.
    const handleCellClickInternal = () => {
        const currentTooltipId = `${item.id}-${columnKey}`;

        if (activeTooltip) {
            setActiveTooltip(null);
            setShowOverlay(false);
            handleCellClick(null, null);
        } else {
            setActiveTooltip(currentTooltipId);
            setShowOverlay(true);
            handleCellClick(item.id, columnKey);
        }
    };

    return (
        <td
            key={item.id}
            className={`position-relative ${pendingChange ? 'pending-change text-black' : ''}`}
        >
            <div
                className="d-flex align-items-center"
                ref={triggerRef}
                onClick={() => fieldConfig?.bulkedit && handleCellClickInternal()}
            >
                <span className={`${!isEditable ? 'cursor-pointer' : ''} ${!fieldValue?.toString() ? 'p-2 editable-table-cell-placeholder' : ''} w-100 table-cell-wrap`}>
                    {fieldConfig?.options
                        ? fieldConfig?.options[fieldValue]
                        : typeof fieldValue === 'object' && fieldValue !== null
                            ? fieldValue.title
                            : fieldValue}
                </span>
            </div>

            {isEditable && showOverlay && overlayPosition.top !== 0 && fieldConfig?.bulkedit && (
                <PortalWrapper>
                    <div
                        className="tooltip-overlay-quick-edit bg-white shadow-sm rounded"
                        ref={tooltipRef}
                        style={{
                            top: overlayPosition.top,
                            left: overlayPosition.left,
                        }}
                    >
                        <div className="tooltip-arrow-quick-edit position-absolute" />
                        <div className="mb-3">
                            {fieldConfig?.fieldType === 'select' && fieldConfig?.options && (
                                <Form.Control
                                    as="select"
                                    value={localValue}
                                    onChange={handleChange}
                                    className="form-select bg-grey text-black"
                                    placeholder={`Hier ${fieldConfig?.fieldLabel} eingeben`}
                                >
                                    <option value="">{`Bitte wählen...`}</option>
                                    {Object.entries(fieldConfig.options!).map(([value, label]) => (
                                        <option key={value} value={value}>
                                            {label as string}
                                        </option>
                                    ))}
                                </Form.Control>
                            )}
                            {fieldConfig?.fieldType === 'textarea' && (
                                <Form.Control
                                    as="textarea"
                                    value={localValue}
                                    onChange={handleChange}
                                    rows={3}
                                    className="form-control bg-grey text-black textarea-no-resize"
                                    placeholder={`Hier ${fieldConfig?.fieldLabel} eingeben`}
                                />
                            )}
                            {(fieldConfig?.fieldType === 'text') && (
                                <Form.Control
                                    type="text"
                                    value={localValue}
                                    onChange={handleChange}
                                    className="form-control bg-grey text-black"
                                    placeholder={`Hier ${fieldConfig?.fieldLabel} eingeben`}
                                />
                            )}

                            {fieldConfig?.fieldType === 'select' && fieldConfig?.optionsprovider?.includes('search') && (
                                <SelectWithSearch
                                    id={columnKey}
                                    label=""
                                    placeholder="Suche..."
                                    apiEndpoint={`${fieldConfig?.optionsprovider.split('?')[0]}`}
                                    onChange={handleSelectChange}
                                    initialValue={null}
                                />
                            )}

                            {fieldConfig?.fieldType === 'select' && fieldConfig?.optionsprovider?.includes('settings') && fetchedOptions && (
                                <Form.Control
                                    as="select"
                                    value={localValue}
                                    onChange={handleChange}
                                    className="form-select bg-grey text-black"
                                    placeholder={`Hier ${fieldConfig?.fieldLabel} eingeben`}
                                >
                                    <option value="">{`Bitte wählen...`}</option>
                                    {Object.entries(fetchedOptions).map(([value, label]) => (
                                        <option key={value} value={value}>
                                            {label as string}
                                        </option>
                                    ))}
                                </Form.Control>
                            )}

                            {fieldConfig?.fieldType === 'date' && (
                                <Form.Control
                                    type="date"
                                    value={localValue}
                                    onChange={handleChange}
                                    className="form-control bg-grey text-black"
                                    placeholder={`Hier ${fieldConfig?.fieldLabel} eingeben`}
                                />
                            )}
                        </div>

                        <div className="horizontal-line my-3"></div>

                        <div className="d-flex justify-content-end">
                            <div>
                                <Button size="sm" className="btn btn-soft-primary me-2" onClick={handleSaveClick}>
                                    Anwenden
                                </Button>
                            </div>
                            <div>
                                <Button size="sm" variant="muted" onClick={handleAbortClick}>
                                    Abbrechen
                                </Button>
                            </div>
                        </div>
                    </div>
                </PortalWrapper>
            )}
        </td>
    );
};

export { DefaultColumnRender };
