import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnumValue } from '../utils/enum';
import { FieldConfig } from '../interfaces';
import { getFieldConfigByResourceName } from '../utils/utils';
import { wvStatusColorMap, wvStatusIconMap } from './filter/iconAndColorMappings';

interface WvStatusIndicatorProps {
    wvStatus: number;
    fieldConfigs: FieldConfig[];
}

const WvStatusIndicator: React.FC<WvStatusIndicatorProps> = ({ wvStatus, fieldConfigs }) => {
    const wvStatusIcon = wvStatusIconMap[Number(wvStatus)];
    const wvStatusColor = wvStatusColorMap[Number(wvStatus)];
    const wvStatusFieldConfig = getFieldConfigByResourceName(fieldConfigs, 'lastDeal.wvStatus');

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${wvStatusColor}`}>
            <FontAwesomeIcon width={15} icon={wvStatusIcon} className="me-2" />
            <span>{getEnumValue(wvStatusFieldConfig?.options ?? {}, wvStatus.toString())}</span>
        </div>
    );
};

export default WvStatusIndicator;
