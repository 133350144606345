import React, { ReactNode, CSSProperties, FC } from 'react';

interface CardProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const CardComponent: FC<CardProps> = React.memo(({ children, className = '', style }) => (
  <div className={`card ${className}`} style={style}>{children}</div>
));

const HeaderComponent: FC<CardProps> = React.memo(({ children, className = '', style }) => (
  <div className={`card-header d-flex justify-content-between ${className}`} style={style}>{children}</div>
));

const BodyComponent: FC<CardProps> = React.memo(({ children, className = '', style }) => (
  <div className={`card-body ${className}`} style={style}>{children}</div>
));

const FooterComponent: FC<CardProps> = React.memo(({ children, className = '', style }) => (
  <div className={`card-footer ${className}`} style={style}>{children}</div>
));

const HeaderTitleComponent: FC<Omit<CardProps, 'style'>> = React.memo(({ children, className = '' }) => (
  <div className={`header-title ${className}`}>{children}</div>
));

const HeaderActionComponent: FC<Omit<CardProps, 'style'>> = React.memo(({ children, className = '' }) => (
  <div className={`header-action ${className}`}>{children}</div>
));

const Card: typeof CardComponent & {
  Header: typeof HeaderComponent;
  Body: typeof BodyComponent;
  Footer: typeof FooterComponent;
  HeaderTitle: typeof HeaderTitleComponent;
  HeaderAction: typeof HeaderActionComponent;
} = CardComponent as any;

Card.Header = HeaderComponent;
Card.Body = BodyComponent;
Card.Footer = FooterComponent;
Card.HeaderTitle = HeaderTitleComponent;
Card.HeaderAction = HeaderActionComponent;

Card.displayName = "Card";

export default Card;
