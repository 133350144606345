import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnumValue } from '../utils/enum';
import { FieldConfig } from '../interfaces';
import { getFieldConfigByResourceName } from '../utils/utils';
import { bgsStatusColorMap, bgsStatusIconMap } from './filter/iconAndColorMappings';

interface BgsStatusIndicatorProps {
    bgsStatus: number;
    fieldConfigs: FieldConfig[];
}

const BgsStatusIndicator: React.FC<BgsStatusIndicatorProps> = ({ bgsStatus, fieldConfigs }) => {
    const bgsStatusIcon = bgsStatusIconMap[Number(bgsStatus)];
    const bgsStatusColor = bgsStatusColorMap[Number(bgsStatus)];
    const bgsStatusFieldConfig = getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus');

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${bgsStatusColor}`}>
            <FontAwesomeIcon width={15} icon={bgsStatusIcon} className="me-2" />
            <span>{getEnumValue(bgsStatusFieldConfig?.options ?? {}, bgsStatus.toString())}</span>
        </div>
    );
};

export default BgsStatusIndicator;
