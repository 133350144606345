import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faHandshake, faThumbsUp } from "@fortawesome/free-solid-svg-icons";

export function getSatisfactionIcon(rating: number) {
    let iconComponent;
    if (rating <= 4) {
        iconComponent = (
            <FontAwesomeIcon
                width={15}
                className="me-1"
                style={{ color: 'var(--bs-danger)' }}
                icon={faThumbsDown}
            />
        )
    } else if (rating > 4 && rating <= 7) {
        iconComponent = (
            <FontAwesomeIcon
                width={15}
                className="me-1"
                style={{ color: 'var(--bs-warning)' }}
                icon={faHandshake}
            />
        );
    } else {
        iconComponent = (
            <FontAwesomeIcon
                width={15}
                className="me-1"
                style={{ color: 'var(--bs-success)' }}
                icon={faThumbsUp}
            />
        );
    }
    return iconComponent;
}

export function getRatingColor(rating: number) {
    if (rating <= 4) {
        return 'text-danger'
    } else if (rating > 4 && rating <= 7) {
        return 'text-warning'
    } else {
        return 'text-success'
    }
}
