import React, { ReactNode, FC } from 'react';
import { useDispatch } from 'react-redux';
import { setSetting } from './store/setting/actions';

// Importing SCSS/CSS files
import './assets/scss/hope-ui.scss';
import './assets/scss/pro.scss';
import './assets/scss/custom.scss';
import './assets/scss/dark.scss';
import './assets/scss/rtl.scss';
import './assets/scss/customizer.scss';
import './assets/custom/scss/custom.scss';
import 'react-nestable/dist/styles/index.css'
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-json-view-lite/dist/index.css';

interface AppProps {
  children?: ReactNode;
}

const App: FC<AppProps> = ({ children }) => {
  const dispatch = useDispatch();
  dispatch(setSetting({}));

  return <div className="App">{children}</div>;
};

export default App;
