import React, { useState } from 'react';
import { FieldConfig, Participant, } from '../../../interfaces';
import Card from '../../bootstrap/card';
import { Button, Col, Row } from 'react-bootstrap';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import StatusDisplay from '../StatusDisplay';
import { getInitials } from '../../../utils/utils';
import Skeleton from 'react-loading-skeleton';
import ParticipantHeaderDropdown from '../../ParticipantHeaderDropdown';
import ArchiveModal from '../../ArchiveModal';
import { usePermissions } from '../../../hooks/usePermissions';
import { PermissionsEnum } from '../../../utils/enum';
import AddEditParticipantModal from '../modal/AddEditParticipantModal';

interface ParticipantDetailsHeaderProps {
    isLoading?: boolean;
    participant?: Participant
    onSubmitSuccess: (participantId: number) => void;
    fieldConfigs: FieldConfig[]
}

const ParticipantDetailsHeader: React.FC<ParticipantDetailsHeaderProps> = ({
    isLoading,
    participant,
    onSubmitSuccess,
    fieldConfigs
}) => {
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions();

    const handleDropdownChange = (action: string) => {
        if (action === 'archive') {
            setShowArchiveModal(true)
        }
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Row>
                        <Col >
                            <div className="d-flex justify-content-between align-items-start align-items-lg-center flex-wrap mb-3">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <ParticipantInitialsComponent size={40} initials={getInitials(participant?.title ?? '')} ></ParticipantInitialsComponent>
                                    <h4 className='mb-0 ms-3'>{isLoading ? <Skeleton width={150} /> : (participant?.title || '-')}</h4>
                                </div>

                                <div className='d-flex mt-2 mt-lg-0'>
                                    <ParticipantHeaderDropdown participant={participant} onActionClick={handleDropdownChange}></ParticipantHeaderDropdown>
                                    <Button
                                        disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write') || !participant || !fieldConfigs}
                                        variant="primary"
                                        onClick={() => setShowAddEditModal(true)}
                                    >
                                        Bearbeiten
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {participant && <StatusDisplay participant={participant} fieldConfigs={fieldConfigs}
                            />}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {showArchiveModal && <ArchiveModal
                modalTitle="Auswahl archivieren?"
                entityIds={[participant?.id!]}
                entityType='participants'
                onSubmit={() => onSubmitSuccess(participant?.id!)}
                onModalClose={() => setShowArchiveModal(false)}>
            </ArchiveModal>}

            {showAddEditModal && <AddEditParticipantModal
                participant={participant}
                modalTitle="Teilnehmer bearbeiten"
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
                fieldConfigs={fieldConfigs}
            >
            </AddEditParticipantModal>
            }
        </>
    );
};

export default ParticipantDetailsHeader;
