import React, { useState, ChangeEvent, useEffect } from 'react';
import { InputGroup, FormControl, Button, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FieldConfig } from '../interfaces';
import { getFieldConfigByResourceName } from '../utils/utils';

interface SearchInputProps {
  onSearch: (data: { query: string, filter: string | undefined }) => void;
  dropdownItems?: string[];
  reset?: boolean;
  hasFilters?: boolean;
  initialSearchTerm?: string
  initialSearchColumn?: string
  fieldConfigs?: FieldConfig[]
}

// SearchInput is a flexible component that allows users to input a search query and optionally select a filter from a dropdown.
// It includes validation to ensure the search term is at least 3 characters long, showing an error message if it's too short.
// The dropdown allows users to filter the search by different fields, which are passed as 'dropdownItems'.
// The component also supports resetting the search field, handling focus, and processing the query on "Enter" or a button click.
// The 'onSearch' function is triggered with the query and selected filter whenever a valid search is submitted.
const SearchInput: React.FC<SearchInputProps> = ({ onSearch, dropdownItems = [], reset, hasFilters, initialSearchTerm, initialSearchColumn = 'all', fieldConfigs }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInputError, setSearchInputError] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = useState<string>();

  const handleSetSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (searchInputError) {
      setSearchInputError(false);
    }
  };

  // performs the search and populates search data to the parent component
  const handleSearch = () => {
    if (!searchValue.trim()) {
      setSearchInputError(true);
    } else if (searchValue.length >= 3) {
      setSearchInputError(false);
      onSearch({ query: searchValue, filter: dropdownValue });
    } else {
      setSearchInputError(true);
    }
  };

  const handleSelectDropdown = (eventKey: string | null) => {
    if (eventKey) {
      setDropdownValue(eventKey);
      onSearch({ query: searchValue, filter: eventKey });
    }
  };

  useEffect(() => {
    if (!searchValue && !isFocused && !dropdownValue) {
      // use initial search info via component props
      if (initialSearchTerm || initialSearchColumn) {
        setSearchValue(initialSearchTerm || '');
        setDropdownValue(initialSearchColumn || undefined);
        onSearch({ query: initialSearchTerm || '', filter: initialSearchColumn || undefined });
      }
    }
  }, [isFocused, searchValue, dropdownValue, onSearch]);

  useEffect(() => {
    if (reset) {
      setSearchValue('');
      setDropdownValue('all');
    }
  }, [reset]);

  return (
    <InputGroup className="search-input">
      {hasFilters && (
        <DropdownButton
          title={dropdownValue === 'all'
            ? 'Alle'
            : getFieldConfigByResourceName(fieldConfigs, dropdownValue!)?.fieldLabel || 'Filter'}
          onSelect={handleSelectDropdown}
        >
          <Dropdown.ItemText>
            <p className="mb-0 custom-font-size-08">
              FELD ZUM DURCHSUCHEN AUSWÄHLEN
            </p>
          </Dropdown.ItemText>
          <Dropdown.Item eventKey={'all'} key={'all'}>
            Alle
          </Dropdown.Item>
          {dropdownItems.map((item, index) => (
            <Dropdown.Item eventKey={item} key={index}>
              {getFieldConfigByResourceName(fieldConfigs, item)?.fieldLabel}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}

      <FormControl
        className="text-black custom-search-input"
        type="search"
        placeholder="Search..."
        value={searchValue}
        onChange={handleSetSearchValue}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          } else if (e.key === 'Escape') {
            setSearchValue('');
            setDropdownValue(undefined);
            onSearch({ query: '', filter: undefined });
          }
        }}
      />
      <Button
        variant=""
        onClick={handleSearch}
        className="border-left-0 text-black bg-grey solid-simple-border"
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </Button>
      {searchInputError && (
        <Form.Control.Feedback type="invalid" className='d-block'>
          Suchebegriff muss min. 3 Zeichen lang sein
        </Form.Control.Feedback>
      )}
    </InputGroup>
  );
};

export default SearchInput;
