import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';

import { ApiClient } from '../services/ApiClient';

type ArchiveModalProps = {
  modalTitle: string;
  entityIds: number[];
  entityType: string;
  onSubmit: (message?: string, isError?: boolean) => void;
  onModalClose: () => void;
};

// This component renders a modal that allows the user to archive a list of entities.
// Props:
// - `modalTitle`: The title displayed in the modal.
// - `entityIds`: An array of IDs representing the entities to be archived.
// - `entityType`: The type of entities being archived (e.g., "user", "post").
// - `onSubmit`: A callback function triggered after attempting to archive the entities. It receives a message and a boolean indicating success or error.
// - `onModalClose`: A callback function to close the modal.

// The component manages a loading state (`isLoading`) to indicate when the archive operation is in progress.
// The `submit` function handles the API requests to archive each entity, updating their status to 3 (archived).
// If the operation succeeds, it calls `onSubmit` with a success message; if it fails, it sends an error message.
// The modal displays a loading spinner while the archive request is in progress and prevents interaction.
const ArchiveModal: React.FC<ArchiveModalProps> = ({
  modalTitle,
  entityIds,
  entityType,
  onSubmit,
  onModalClose
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);
    try {
      for (const entityId of entityIds) {
        await ApiClient.put(`/${entityType}/${entityId}`, {
          status: 3,
        })
      }
      onSubmit(`Erfolgreich archiviert`, false)
    } catch (error) {
      onSubmit(`Fehler beim Archivieren`, true)
    } finally {
      setIsLoading(false);
      onModalClose();
    }
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Bist du dir sicher, dass du ausgewählten Elemente archivieren möchtest?</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-soft-primary" variant="secondary" onClick={onModalClose}>
            Abbrechen
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="secondary"
            className="btn-danger"
            onClick={submit}
          >
            Archivieren
            {isLoading && (
              <Spinner
                className="ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              ></Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ArchiveModal;
