import {
    memo,
    Fragment,
    useState,
    useEffect,
} from 'react';
import { Row, Col, Table, Form, Button, Dropdown } from 'react-bootstrap';
import {
    getEnumValue,
    PermissionsEnum,
} from '../../utils/enum';
import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../hooks/useSortableData';
import { useSelection } from '../../hooks/useSelection';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    ComboButtonId,
} from '../../components/ComboButtonGroup';
import { ColumnMapping, FieldConfig, Deal } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import SearchInput from '../../components/SearchInput';
import useResetUrlParams from '../../hooks/useResetUrlParams';
import ViewSelector from '../../components/ViewSelector';
import { DefaultColumnRender } from '../../components/table/DefaultColumnRender';
import ColumnSelection from '../../components/ColumnSelection';
import { defaultRenderHeader } from '../../components/table/utils';
import SaveCancelPopup from '../../components/table/SaveCancelPopup';
import ResetFiltersButton from '../../components/ResetFilterButton';
import { addPrefixToFilters, getFieldConfigByResourceName } from '../../utils/utils';
import GenericDropdownFilter from '../../components/filter/GenericDropdownFilter';
import { dealStatusColorMap, dealStatusIconMap, } from '../../components/filter/iconAndColorMappings';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import PortalWrapper from '../../components/PortalWrapper';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

export interface DealFilters {
    status: | number | null
}

interface DealsResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    searchFilters: string[];
    list: Deal[];
}

const Deals = memo(() => {
    const navigate = useNavigate();
    const resetUrlParams = useResetUrlParams();
    const { companyId = 'oc' } = useParams();
    const { show, message, error, showToast, hideToast } = useToast();
    const [selectedSearchColumn, setSelectedSearchColumn] = useState<ComboButtonId | ''>('all');
    const [deals, setDeals] = useState<Deal[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(20);
    const [limit, setLimit] = useState<number>(25);
    const [totalEntries, setTotalEntries] = useState<number>(200);
    const [availableFilter, setAvailableFilter] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
    const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
    const [showPopup, setShowPopup] = useState(false);
    const { requestSort, sortConfig, setSortConfig, getSortCaret } = useSortableData(deals, showPopup);
    const [pendingChanges, setPendingChanges] = useState<{ [key: string]: any }>({});
    const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
    const [editableCell, setEditableCell] = useState<{ rowId: number | null; columnKey: string | null }>({
        rowId: null,
        columnKey: null,
    });
    const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<DealFilters>({
        status: null,
    });

    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const {
        selectedItems: selectedDeals,
        isAllSelected,
        selectedCount,
        handleSelectAll,
        handleDeSelectAll,
        handleSelectRow
    } = useSelection(deals);

    // Fetch the list of deals based on the current filters, search, sort and pagination
    const fetchDeals = async () => {
        setDeals([]);
        setIsLoading(true);
        let queryParams = `?page=${currentPage}`;

        if (selectedFilters.status?.toString()) {
            queryParams += `&deals.status=${selectedFilters.status?.toString()}`;
        }

        if (limit.toString()) {
            queryParams += `&limit=${limit}`;
        }
        if (searchQuery) {
            queryParams += `&search=${encodeURIComponent(searchQuery)}`;
            if (selectedSearchColumn !== 'all') {
                queryParams += `&column=${encodeURIComponent(selectedSearchColumn)}`;
            }
        }

        if (sortConfig?.field) {
            queryParams += `&sort[field]=${encodeURIComponent(sortConfig.field)}`;
            queryParams += `&sort[type]=${sortConfig.type}`;
        }

        try {
            const response = await ApiClient.get(`/deals${queryParams}`);
            const dealsResponse = response.data as DealsResponse;
            setTotalPages(dealsResponse.amountPages);
            setDeals(dealsResponse.list ?? []);
            setCurrentPage(dealsResponse.page);
            setLimit(dealsResponse.itemsPerPage);
            setTotalEntries(dealsResponse.amountAllItems);
            setAvailableFilter(addPrefixToFilters(dealsResponse.searchFilters, 'deals'));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    // Fetches the field configuration for rendering dynamic columns
    const fetchFieldConfig = async (endpoint: string) => {
        try {
            const response = await ApiClient.get(`/${endpoint}/columns`);
            const data = response.data;

            // Check if deals is present and set as array into state
            if (data.deals) {
                setFieldConfigs([...Object.values(data.deals) as FieldConfig[]]);
            }
        } catch (error) {
            console.error("Error fetching field configurations:", error);
        }
    };


    // Effect to trigger fetching of deals when filters, search, paginationm or other dependenies change
    useEffect(() => {
        if (permissionsLoaded) {
            const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read');

            if (hasPermission) {
                fetchDeals();

                // Fetch only once
                if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
                    fetchFieldConfig('deals');
                }

            } else {
                navigate('/errors/error404');
            }
        }
    }, [selectedFilters, currentPage, searchQuery, selectedSearchColumn, selectedColumns, limit, sortConfig]);

    // Effect to reset search input
    useEffect(() => {
        if (resetSearchInput) {
            setResetSearchInput(false);
        }
    }, [resetSearchInput]);

    // Routes to detail view
    const handleRouteToDetailView = (dealsId: number) => {
        navigate(`/${companyId}/deals/${dealsId}`);
    };

    // Fetch deals again after an update, optionally showing a message
    const handleDealsUpdateSubmit = (message?: string, isError?: boolean) => {
        fetchDeals();
        if (message) {
            showToast(message, isError);
        }
    };

    // Resets all filters and search fields
    const resetSearch = () => {
        resetUrlParams();
        setSearchQuery('');
        setSelectedSearchColumn('all');
        setCurrentPage(1);
        setResetSearchInput(true);
    };

    // Handles view selection changes from the view selection component.
    const handleSelectionChange = (
        selectedColumns: string[],
        selectedFilters: any,
        selectedSortConfig: any,
        selectedLimit: number,
        selectedSearchTerm: string,
        selectedSearchColumn: string
    ) => {
        resetSearch()
        setSelectedColumns(selectedColumns);
        setSelectedFilters(selectedFilters);
        setSortConfig(selectedSortConfig);
        setLimit(selectedLimit);

        if (selectedSearchTerm || selectedSearchColumn) {
            setSearchQuery(selectedSearchTerm);
            setSelectedSearchColumn(selectedSearchColumn);
        }
    };

    // Handle clicks on table cells for editing
    const handleCellClick = (rowId: number | null, columnKey: string | null) => {
        setEditableCell({ rowId, columnKey });
    };

    // Handle changes to table cells for editing
    const handleFieldChange = (rowId: number, columnKey: string, value: any) => {
        setPendingChanges(prevChanges => ({
            ...prevChanges,
            [`${rowId}-${columnKey}`]: { rowId, columnKey, value },
        }));
        setShowPopup(true);
    };

    // Save changes made in editable cells
    const handleSaveChange = async () => {
        const changesToSubmit = Object.values(pendingChanges);

        try {
            const updatePromises = changesToSubmit.map(change => {
                if (typeof change.value === 'object' && change.value !== null) {
                    return ApiClient.put(`/deals/${change.rowId}`, { [`${getFieldConfigByResourceName(fieldConfigs, change.columnKey)?.fieldName as string}_id`]: change.value.value });
                } else {
                    return ApiClient.put(`/deals/${change.rowId}`, { [getFieldConfigByResourceName(fieldConfigs, change.columnKey)?.fieldName as string]: change.value });
                }
            });

            await Promise.all(updatePromises);

            setPendingChanges({});
            setEditableCell({ rowId: null, columnKey: null });

            await fetchDeals();
            showToast('Erfolgreich gespeichert', false);
            setShowPopup(false);
        } catch (error) {
            showToast('Fehler beim Speichern', true);
            console.error('Error saving the changes:', error);
        }
    };

    // Revert a single change in editable cell
    const handleRevertChange = () => {
        const { rowId, columnKey } = editableCell;

        if (rowId === null || columnKey === null) return;

        const newPendingChanges = { ...pendingChanges };
        delete newPendingChanges[`${rowId}-${columnKey}`];

        setPendingChanges(newPendingChanges);
        setEditableCell({ rowId: null, columnKey: null });
        setShowPopup(Object.keys(newPendingChanges).length > 0);
    };

    // Revert all changes made in the editable cells
    const handleRevertChanges = () => {
        setPendingChanges({});
        setEditableCell({ rowId: null, columnKey: null });
        setShowPopup(false);
    };

    // Handle search input change
    const handleSearch = (data: { query: string; filter?: string }) => {
        const { query, filter } = data;

        if (query) {
            setSearchQuery(query);
            setSelectedSearchColumn(filter || '');
            setCurrentPage(1);
        } else if (searchQuery) {
            resetSearch();
        }
    };

    // Define how special columns should be rendered
    const columnMapping: { [key: string]: ColumnMapping<Deal> } = {
        'deals.title': {
            label: 'Titel',
            renderHeader: (key: any) => (
                <th
                    key={key}
                    className="sticky-col cursor-pointer"
                    scope="col"
                    title="Titel"
                    onClick={() => requestSort('deals.title')}
                >
                    <div className="d-flex align-items-center position-relative table-cell-wrap max-w-100">
                        <Form.Check
                            disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewDeals, 'delete')}
                            className="me-3"
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                        Titel <div className="position-absolute" style={{ right: 0 }}>{getSortCaret('deals.title')}</div>
                    </div>
                </th>
            ),

            render: (deal: Deal) => (
                <td key={deal.id} className="sticky-col">
                    <div className="d-flex align-items-center justify-content-start">
                        <Form.Check
                            disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewDeals, 'delete')}
                            className="me-3"
                            type="checkbox"
                            checked={selectedDeals[deal.id] ?? false}
                            onChange={() => { }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSelectRow(deal.id, e);
                            }}
                        />
                        <Link
                            to={`/${companyId}/deals/${deal.id}`}
                            className="btn btn-link ps-0 text-start table-cell-wrap max-w-table-title"
                            title={deal.title ?? ''}
                        >
                            {deal.title}
                        </Link>
                    </div>
                </td>
            ),
        },
        'deals.status': {
            label: 'Dealstatus',
            render: (deal: Deal) => (
                <td key={`${deal.id}-status`}>
                    <FontAwesomeIcon
                        icon={dealStatusIconMap[deal.status]}
                        className={`me-2 ${dealStatusColorMap[deal.status]}`}
                    />
                    {getEnumValue(
                        getFieldConfigByResourceName(fieldConfigs, 'deals.status')?.options ?? {},
                        deal.status.toString()
                    )}
                </td>
            ),
        },
    };

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                <h3>Deals</h3>
            </div>
            <Card className="card-block card-stretch card-height">
                <Card.Body>
                    <Row className="d-flex justify-content-between mb-4">
                        <Col md={9}>
                            {searchQuery &&
                                <div className="d-flex align-items-baseline mb-3">
                                    <h4 className="m-0">Suchergebnisse</h4>
                                    <span className="ms-3 d-flex align-items-baseline">
                                        <Button
                                            className="m-0 p-0 fs-6"
                                            variant="link"
                                            onClick={resetSearch}
                                        >
                                            Suche beenden
                                        </Button>
                                    </span>
                                </div>
                            }
                            <ViewSelector
                                selectedSortConfig={sortConfig}
                                selectedFilters={selectedFilters}
                                selectedColumns={selectedColumns}
                                selectedLimit={limit}
                                selectedSearchColumn={selectedSearchColumn}
                                selectedSearchTerm={searchQuery}
                                entityType='deals'
                                onSelectionChange={handleSelectionChange}
                            />
                        </Col>
                        <Col md={3}>
                            <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} initialSearchTerm={searchQuery} initialSearchColumn={selectedSearchColumn} fieldConfigs={fieldConfigs} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="d-flex custom-scrollbar-x horizontal-scroll">
                                    {/* Status Filter */}
                                    <GenericDropdownFilter
                                        selectedFilter={selectedFilters.status ?? null}
                                        handleFilterChange={(status) => {
                                            setSelectedFilters(filters => ({ ...filters, status: status }));
                                            setCurrentPage(1);
                                        }}
                                        filterEnum={getFieldConfigByResourceName(fieldConfigs, 'deals.status')?.options ?? {}}
                                        iconMap={dealStatusIconMap}
                                        colorMap={dealStatusColorMap}
                                        isDisabled={false}
                                        titlePlaceholder="Dealstatus">
                                    </GenericDropdownFilter>

                                    <div className="sticky-right-reset-filter">
                                        <ResetFiltersButton filters={selectedFilters} setFilters={(newFilters: DealFilters) => {
                                            setSelectedFilters(newFilters);
                                            setCurrentPage(1);
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {selectedCount > 0 && (
                <GeneralSelectionActions
                    selectedItems={selectedDeals}
                    selectedCount={selectedCount}
                    handleDeSelectAll={handleDeSelectAll}
                    onSubmitSuccess={handleDealsUpdateSubmit}
                    amountAllItems={totalEntries}
                    entityType='deals'
                ></GeneralSelectionActions>
            )}

            <div id='scroll-container' style={{ overflowX: 'auto' }}>
                <Table responsive="md" size="sm" style={{ overflow: 'auto' }}>
                    <thead>
                        <tr>
                            {selectedColumns.map((columnKey) =>
                                columnMapping[columnKey]?.renderHeader
                                    ? columnMapping[columnKey].renderHeader!(columnKey)
                                    : defaultRenderHeader(columnKey, fieldConfigs, requestSort, getSortCaret,)
                            )}

                            <th className="cursor-pointer text-end align-top sticky-right bg-grey w-40-px" scope="col">
                                <ColumnSelection
                                    selectedColumns={selectedColumns}
                                    onSelectionChange={columns => setSelectedColumns(columns)}
                                    fieldConfigs={fieldConfigs}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading
                            ? Array.from({ length: 8 }).map((_, index) => (
                                <SkeletonRow key={`skeleton-row-${index}`} columnCount={selectedColumns.length + 1} />
                            ))
                            : deals.map((deal) => (
                                <tr key={deal.id} className="bg-white">
                                    {selectedColumns.map((columnKey) =>
                                        columnMapping[columnKey]
                                            ? columnMapping[columnKey].render(deal)
                                            : <DefaultColumnRender
                                                key={`${deal.id}-${columnKey}`}
                                                item={deal}
                                                columnKey={columnKey}
                                                editableCell={editableCell}
                                                handleCellClick={handleCellClick}
                                                handleFieldChange={handleFieldChange}
                                                handleRevertChange={handleRevertChange}
                                                fieldConfigs={fieldConfigs}
                                                pendingChanges={pendingChanges}
                                                activeTooltip={activeTooltip}
                                                setActiveTooltip={setActiveTooltip}
                                            />
                                    )}
                                    <td className='sticky-right bg-white' key={deal.id}>
                                        <Dropdown>
                                            <Dropdown.Toggle as="div" className="no-caret cursor-pointer d-inline-block">
                                                <div className="px-2">
                                                    <FontAwesomeIcon icon={faEllipsisVertical} />
                                                </div>
                                            </Dropdown.Toggle>
                                            <PortalWrapper>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleRouteToDetailView(deal.id)}><FontAwesomeIcon className='me-1' width={15} icon={faCircleInfo} /> Mehr Details</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </PortalWrapper>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                {!isLoading && deals.length === 0 && (
                    <div
                        className="d-flex justify-content-center align-items-center border rounded my-3"
                        style={{ height: '50px' }}
                    >
                        <p className="p-0 m-0">Keine Deals gefunden</p>
                    </div>
                )}
            </div>

            {totalEntries > 0 && (
                <Row>
                    <Col>
                        <PaginationInfo
                            currentPage={currentPage}
                            limit={limit}
                            totalEntries={totalEntries}
                            onLimitChange={(size) => {
                                setLimit(size);
                                setCurrentPage(1);
                            }}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <DynamicPagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row>
            )}

            {showPopup && (
                <SaveCancelPopup
                    onSave={handleSaveChange}
                    onAbort={handleRevertChanges}
                    pendingChangesCount={Object.keys(pendingChanges).length}
                />
            )}

            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </Fragment>
    );
});

export default Deals;
