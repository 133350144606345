import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Spinner,
  Dropdown,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ApiClient } from '../services/ApiClient';

type DeleteModalProps = {
  modalTitle: string;
  buttonName: string;
  entityIds: number[];
  entityType: string;
  onSubmit: (message: string, isError: boolean) => void;
  onDeleteModalChange?: (open: boolean) => void;
  isDropdownItem?: boolean;
  isDisabled?: boolean
};

const DeleteModal: React.FC<DeleteModalProps> = ({
  modalTitle,
  buttonName,
  entityIds,
  entityType,
  onSubmit,
  onDeleteModalChange,
  isDropdownItem,
  isDisabled
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    onDeleteModalChange && onDeleteModalChange(false);
    setShowModal(false);
  };

  const handleShow = () => {
    onDeleteModalChange && onDeleteModalChange(true);
    setShowModal(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);
    try {
      for (const entityId of entityIds) {
        await ApiClient.delete(`/${entityType}/${entityId}`);
      }
      onSubmit(`Erfolgreich gelöscht`, false)
    } catch (error) {
      onSubmit(`Fehler beim Löschen`, true)
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      {isDropdownItem ?
        <Dropdown.Item disabled={isDisabled} as="div" className={`text-danger dropdownDeleteButton ${isDisabled ? 'disabled-item' : ''}`} onClick={handleShow}><FontAwesomeIcon width={30} icon={faTrash} />Löschen</Dropdown.Item>
        :
        <Button disabled={isDisabled} variant="link" className='text-danger' onClick={handleShow}>
          {buttonName}
        </Button>
      }
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Bist du dir sicher, dass du die ausgewählten Elemente unwiderruflich löschen möchtest? Gelöschte Elemente können nicht wieder hergestellt werden!</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-soft-primary" variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="danger"
            onClick={submit}
          >
            Löschen
            {isLoading && (
              <Spinner
                className="ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              ></Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
