import React from 'react';
import { Pagination } from 'react-bootstrap';

interface DynamicPaginationProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const DynamicPagination: React.FC<DynamicPaginationProps> = ({
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginationItems: JSX.Element[] = [];

  paginationItems.push(
    <Pagination.Prev
      key="prev"
      onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
      disabled={currentPage === 1}
    />
  );

  paginationItems.push(
    <Pagination.Item
      key={1}
      active={1 === currentPage}
      onClick={() => handlePageChange(1)}
    >
      1
    </Pagination.Item>
  );

  let startPage: number, endPage: number;

  if (totalPages <= 5) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  if (startPage > 2) {
    paginationItems.push(<Pagination.Ellipsis key="startEllipsis" disabled />);
  }

  for (let number = startPage; number <= endPage; number++) {
    if (number === 1 || number === totalPages) continue;
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  if (endPage < totalPages - 1) {
    paginationItems.push(<Pagination.Ellipsis key="endEllipsis" disabled />);
  }

  if (totalPages > 1) {
    paginationItems.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === currentPage}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  paginationItems.push(
    <Pagination.Next
      key="next"
      onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
      disabled={currentPage === totalPages}
    />
  );

  return <Pagination>{paginationItems}</Pagination>;
};

export default DynamicPagination;
