import {
    faCircle,
    faCircleCheck,
    faCircleX,
    faSpinner,
    faSadCry,
    faQuestionCircle,
    faBriefcase,
    faBan,
    faExclamationTriangle,
    faFile,
    faFileAlt,
    faPaperPlane,
    faTimesCircle,
    faFileContract,
    faEnvelopeOpen,
    faFileSignature,
    faPersonBreastfeeding,
    faClock,
    IconDefinition
} from '@fortawesome/pro-regular-svg-icons';
import { faHourglass, faHourglassHalf } from '@fortawesome/pro-duotone-svg-icons';
import { faFrown, faGrin, faMeh, faSadTear, faSmile } from '@fortawesome/pro-solid-svg-icons';

export const abortReasonIconMap: { [key: string]: IconDefinition } = {
    workFound: faBriefcase,
    workFoundNotParttime: faBriefcase,
    rival: faSadCry,
    lostValidity: faExclamationTriangle,
    lost: faExclamationTriangle,
    cancellation: faBan,
    maternityLeave: faPersonBreastfeeding,
    terminationbyProvider: faBan,
    notStarted: faQuestionCircle,
    notKnown: faQuestionCircle,
};

export const abortReasonColorMap: { [key: string]: string } = {
    workFound: 'text-gray',
    workFoundNotParttime: 'text-gray',
    rival: 'text-gray',
    lostValidity: 'text-gray',
    lost: 'text-gray',
    maternityLeave: 'text-gray',
    cancellation: 'text-gray',
    terminationbyProvider: 'text-gray',
    notStarted: 'text-gray',
    notKnown: 'text-gray',
};

export const dealStatusIconMap: { [key: number]: IconDefinition } = {
    0: faCircle,
    1: faSpinner,
    2: faCircleCheck,
    3: faCircleX,
};

export const dealStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-success',
    3: 'text-danger',
};

export const timeModelIconMap: { [key: string]: any } = {
    fulltime: faHourglass,
    parttime: faHourglassHalf,
    besidesTheJob: faClock
};

export const timeModelColorMap: { [key: string]: string } = {
    fulltime: 'text-gray',
    parttime: 'text-gray',
    besidesTheJob: 'text-gray'
};

export const bgsStatusIconMap: { [key: number]: IconDefinition } = {
    0: faBan,
    1: faFile,
    2: faFileAlt,
    3: faPaperPlane,
};

export const bgsStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-success',
};

export const wvStatusIconMap: { [key: number]: IconDefinition } = {
    0: faCircleX,
    1: faFileContract,
    2: faPaperPlane,
    3: faEnvelopeOpen,
    4: faBan,
    5: faFileSignature,
    6: faPaperPlane,
};

export const wvStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-warning',
    4: 'text-danger',
    5: 'text-success',
    6: 'text-success',
};

export const smileyMap: Record<number, IconDefinition> = {
    1: faSadTear,
    2: faFrown,
    3: faMeh,
    4: faSmile,
    5: faGrin,
};

export const smileyColorMap: Record<number, string> = {
    1: '#FF6B6B',
    2: '#FFC107',
    3: '#FFEB3B',
    4: '#8BC34A',
    5: '#4CAF50',
};