import React, { memo, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';

const SidebarMenu = memo((props) => {
    let location = useLocation();

    const handleOnClick = (e) => {
        if (props.onClick) {
            e.preventDefault();
            props.onClick(e);
        }
        if (location.pathname === props.pathname) {
            e.preventDefault();
            window.location.href = props.pathname;
        }
    };

    return (
        <Fragment>
            {props.isTag === 'true' && (
                <li className={`${location.pathname === props.pathname || location.pathname.startsWith(`${props.pathname}/`) ? 'active' : ''} nav-item`}>
                    <Link
                        to={props.pathname}
                        className={`${location.pathname === props.pathname || location.pathname.startsWith(`${props.pathname}/`) ? 'active' : ''} nav-link`}
                        aria-current="page"
                        onClick={handleOnClick}
                    >
                        <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                            {props.children}
                        </OverlayTrigger>
                        <span className="item-name">{props.title}</span>
                    </Link>
                </li>
            )}
            {props.isTag === 'false' && (
                <Nav.Item as="li">
                    <Link
                        to={props.pathname}
                        className={`${location.pathname === props.pathname || location.pathname.startsWith(`${props.pathname}/`) ? 'active' : ''} nav-link`}
                        onClick={handleOnClick}
                        target={props.target}
                    >
                        {props.staticIcon === 'true' && (
                            <i className="icon">
                                {/* SVG and other elements */}
                            </i>
                        )}
                        {props.children}
                        <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                            <i className="sidenav-mini-icon">{props.minititle}</i>
                        </OverlayTrigger>
                        <span className="item-name">{props.title}</span>
                    </Link>
                </Nav.Item>
            )}
        </Fragment>
    );
});

SidebarMenu.displayName = "SidebarMenu";
export default SidebarMenu;
