import { memo, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import SidebarMenu from '../../../sidebar/sidebar-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faUser,
  faUserGraduate,
  faBoxesStacked,
  faWebhook,
  faUsers,
  faShieldKeyhole,
  faImage,
  faHouse,
  faRightFromBracket,
  faGear,
  faWavePulse,
  faClipboardUser,
} from '@fortawesome/pro-solid-svg-icons';

import { faFaceSmile } from '@fortawesome/pro-light-svg-icons';
import { PermissionsEnum } from '../../../../utils/enum';
import { usePermissions } from '../../../../hooks/usePermissions';

const VerticalNav = memo(() => {
  const { userHasPermissionByRight } = usePermissions();
  const { companyId = 'oc' } = useParams();
  let navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    navigate(`/${companyId}/login`);
  };

  const hasModulePermissions = () => {
    return (
      userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewProducts, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewMedia, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewActivities, 'read')
    );
  };

  const hasAdministrationPermissions = () => {
    return (
      userHasPermissionByRight(PermissionsEnum.ViewUsers, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewRoles, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewWebhooks, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read')
    );
  };

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <SidebarMenu isTag="true" pathname={`/${companyId}/`} title="Dashboard">
          <div>
            <FontAwesomeIcon width={15} icon={faHouse} />
          </div>
        </SidebarMenu>
      </Accordion>

      {hasModulePermissions() && (
        <Accordion as="ul" className="navbar-nav iq-main-menu">
          <div className="d-flex flex-column justify-content-center">
            <li className="nav-item static-item ps-0">
              <Link
                className="nav-link static-item disabled"
                to="#"
                tabIndex={-1}
              >
                <span className="default-icon ps-1">MODULE</span>
                <div className="mini-icon" style={{ paddingLeft: '10px' }}>
                  -
                </div>
              </Link>
            </li>
            {userHasPermissionByRight(
              PermissionsEnum.ViewParticipants,
              'read'
            ) && (
              <SidebarMenu
                isTag="true"
                pathname={`/${companyId}/participants`}
                title="Teilnehmer"
              >
                <div>
                  <FontAwesomeIcon width={15} icon={faUserGraduate} />
                </div>
              </SidebarMenu>
            )}
            {userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read') && (
              <SidebarMenu
                isTag="true"
                pathname={`/${companyId}/deals`}
                title="Deals"
              >
                <div>
                  <FontAwesomeIcon width={15} icon={faHandshake} />
                </div>
              </SidebarMenu>
            )}
            {userHasPermissionByRight(PermissionsEnum.ViewProducts, 'read') && (
              <SidebarMenu
                isTag="true"
                pathname={`/${companyId}/products`}
                title="Produkte"
              >
                <div>
                  <FontAwesomeIcon width={15} icon={faBoxesStacked} />
                </div>
              </SidebarMenu>
            )}
            {userHasPermissionByRight(
              PermissionsEnum.ViewSatisfactions,
              'read'
            ) && (
              <SidebarMenu
                isTag="true"
                pathname={`/${companyId}/satisfactions`}
                title="Zufriedenheiten"
              >
                <div>
                  <FontAwesomeIcon width={15} icon={faFaceSmile} />
                </div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(
              PermissionsEnum.ViewActivities,
              'read'
            ) && (
              <SidebarMenu
                isTag="true"
                pathname={`/${companyId}/activities`}
                title="Aktivitäten"
              >
                <div>
                  <FontAwesomeIcon width={15} icon={faWavePulse} />
                </div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(
              PermissionsEnum.ViewAttendances,
              'read'
            ) && (
              <SidebarMenu
                isTag="true"
                pathname={`/${companyId}/attendances`}
                title="Anwesenheiten"
              >
                <div>
                  <FontAwesomeIcon width={15} icon={faClipboardUser} />
                </div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewMedia, 'read') && (
              <SidebarMenu
                isTag="true"
                pathname={`/${companyId}/media/0`}
                title="Medien"
              >
                <div>
                  <FontAwesomeIcon width={15} icon={faImage} />
                </div>
              </SidebarMenu>
            )}
          </div>
        </Accordion>
      )}

      {hasAdministrationPermissions() && (
        <Accordion as="ul" className="navbar-nav iq-main-menu">
          <li className="nav-item static-item ps-0">
            <Link
              className="nav-link static-item disabled"
              to="#"
              tabIndex={-1}
            >
              <span className="default-icon ps-1">ADMINISTRATION</span>
              <div className="mini-icon" style={{ paddingLeft: '10px' }}>
                -
              </div>
            </Link>
          </li>
          {userHasPermissionByRight(PermissionsEnum.ViewUsers, 'read') && (
            <SidebarMenu
              disabled
              isTag="true"
              pathname={`/${companyId}/users`}
              title="Nutzerverwaltung"
            >
              <div>
                <FontAwesomeIcon width={15} icon={faUser} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.ViewRoles, 'read') && (
            <SidebarMenu
              disabled
              isTag="true"
              pathname={`/${companyId}/roles`}
              title="Rollenverwaltung"
            >
              <div>
                <FontAwesomeIcon width={15} icon={faUsers} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'read') && (
            <SidebarMenu
              isTag="true"
              pathname={`/${companyId}/apikeys`}
              title="API-Schlüssel"
            >
              <div>
                <FontAwesomeIcon width={15} icon={faShieldKeyhole} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.ViewWebhooks, 'read') && (
            <SidebarMenu
              isTag="true"
              pathname={`/${companyId}/webhooks`}
              title="Webhooks"
            >
              <div>
                <FontAwesomeIcon width={15} icon={faWebhook} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read') && (
            <SidebarMenu
              disabled
              isTag="true"
              pathname={`/${companyId}/settings`}
              title="Einstellungen"
            >
              <div>
                <FontAwesomeIcon width={15} icon={faGear} />
              </div>
            </SidebarMenu>
          )}
        </Accordion>
      )}

      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <SidebarMenu
          pathname={`/${companyId}/login`}
          isTag="true"
          title="Ausloggen"
          onClick={handleLogout}
        >
          <div>
            <FontAwesomeIcon width={15} icon={faRightFromBracket} />
          </div>
        </SidebarMenu>
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = 'VerticalNav';
export default VerticalNav;
