import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getEnumValue,
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { FieldConfig, Participant } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import ParticipantDetailsHeader from '../../components/participants/detail/ParticipantDetailsHeader';
import ParticipantDetails from '../../components/participants/detail/ParticipantDetails';
import ComboButtonGroup, { ComboButton, ComboButtonId } from '../../components/ComboButtonGroup';
import DealInfos from '../../components/participants/detail/DealInfos';
import { formatDealMonthYear, formatEuro, getDealLabel, getFieldConfigByResourceName } from '../../utils/utils';
import { faHouse, faRectangleHistory, faFileInvoiceDollar, faCalendar, faSmile, faFile, faKey, faPlus } from '@fortawesome/pro-regular-svg-icons';
import CollapsibleCard from '../../components/CollapsibleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dealStatusColorMap, dealStatusIconMap } from '../../components/filter/iconAndColorMappings';

function ParticipantDetail() {
  const { participantId } = useParams();
  const [participant, setParticipant] = useState<Participant>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ComboButtonId | ''>('participant_overview');
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const { show, message, error, hideToast } = useToast();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read');

      if (hasPermission) {
        participantId && fetchParticipant(participantId);
        // Fetch only once
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchFieldConfig('participants');
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [participantId, permissionsLoaded]);

  const fetchParticipant = async (participantId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/participants/${participantId}`);
      const participantResponse = response.data;
      setParticipant(participantResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  const fetchFieldConfig = async (endpoint: string) => {
    try {
      const response = await ApiClient.get(`/${endpoint}/columns`);
      const data = response.data;

      // Check if participants and lastDeal fieldConfigs are present and combine them into an array
      if (data.participants && data.lastDeal) {
        const combinedFieldConfigs = [
          ...Object.values(data.lastDeal),
          ...Object.values(data.participants),
        ];
        setFieldConfigs(combinedFieldConfigs as FieldConfig[]);
      }
    } catch (error) {
      console.error("Error fetching field configurations:", error);
    }
  };

  const handleModalSubmit = () => {
    setParticipant(undefined);
    participantId && fetchParticipant(participantId);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const comboButtons: ComboButton[] = useMemo(() => {
    const dealsCount = participant?.deals.length;
    return [
      { id: 'participant_overview', label: 'Übersicht', icon: faHouse },
      { id: 'participant_activities', label: 'Aktivitäten', icon: faRectangleHistory },
      { id: 'participant_deals', label: `Deals (${dealsCount})`, icon: faFileInvoiceDollar },
      { id: 'participant_lessons', label: 'Unterricht', icon: faCalendar },
      { id: 'participant_satisfactions', label: 'Zufriedenheit', icon: faSmile },
      { id: 'participant_documents', label: 'Unterlagen', icon: faFile },
      { id: 'participant_logins', label: 'Logins', icon: faKey },
    ] as ComboButton[];
  }, [participant]);

  return (
    <Fragment>
      <Row>
        <Col >
          <ParticipantDetailsHeader
            participant={participant}
            isLoading={isLoading}
            onSubmitSuccess={handleModalSubmit}
            fieldConfigs={fieldConfigs}>
          </ParticipantDetailsHeader>
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedTab}
                    setSelectedCombo={handleSelectedTab}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {selectedTab === 'participant_overview' &&
                <>
                  <CollapsibleCard
                    title="Aktueller Deal"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary" onClick={() => setSelectedTab('participant_deals')}>
                        Alle anzeigen
                      </Button>
                    }
                    disabled={true || !participant?.lastDeal}
                  >
                    {/* <DealInfos deal={participant?.lastDeal} participant={participant} label={getDealLabel(participant?.lastDeal?.start!, true)} onSubmitSuccess={handleModalSubmit}></DealInfos> */}
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Angepinnte Aktivitäten"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                    disabled
                  >
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Unsere Teilnehmerbewertung"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                    disabled
                  >
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Feedback vom Teilnehmer"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                    disabled
                  >
                  </CollapsibleCard>
                </>
              }
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          {/* Collapsible Card Participant Details */}
          <CollapsibleCard
            title="Details"
            showDivider
            initialOpen
          >
            <div className='p-3'>
              <ParticipantDetails participant={participant} isLoading={isLoading} fieldConfigs={fieldConfigs}></ParticipantDetails>
            </div>

          </CollapsibleCard>

          {/* Collapsible Card Deals */}
          <CollapsibleCard
            title="Deals"
            itemsLength={participant?.deals.length}
          >
            <ListGroup className="p-3">
              {participant?.deals.map((deal) => (
                <ListGroup.Item
                  key={deal.id}
                  className="d-flex justify-content-between align-items-center mb-3 border rounded cursor-pointer"
                  onClick={() => setSelectedTab('participant_deals')}
                >
                  <div className='p-1'>
                    <div className="mb-1">{formatDealMonthYear(deal)} <span className='text-muted'>({deal.bgsnumber})</span></div>
                    <div>
                      <span className={`${dealStatusColorMap[deal.status]}`}>
                        <FontAwesomeIcon icon={dealStatusIconMap[deal.status]} className='me-1' />
                        <span>
                          {getEnumValue(
                            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {},
                            deal.status?.toString()
                          )}
                        </span>
                      </span>
                      <span className='text-muted'>
                        {` · ${formatEuro(deal.priceTotalGross)} · ${deal.ue} UE`}
                      </span>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </CollapsibleCard>

          {/* Collapsible Card Products */}
          <CollapsibleCard
            title="Produkte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Locations */}
          <CollapsibleCard
            title="Standorte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Geräte */}
          <CollapsibleCard
            title="Geräte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Persons */}
          <CollapsibleCard
            title="Personen"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Employer */}
          <CollapsibleCard
            title="Arbeitgeber"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default ParticipantDetail;
