import React, { useEffect, memo } from 'react';

//react-bootstrap
import { Navbar, Container } from 'react-bootstrap';

//router
import { Link } from 'react-router-dom';

// Redux Selector / Action
import { useSelector } from 'react-redux';

import oneCareerLogo from '../../../../assets/images/onecareer-logo.svg';

// Import selectors & action from setting store
import * as SettingSelector from '../../../../store/setting/selectors';

const Headerpro = memo((props) => {
  const navbarHide = useSelector(SettingSelector.navbar_show); // array
  const themeFontSize = useSelector(SettingSelector.theme_font_size);
  const headerNavbar = useSelector(SettingSelector.header_navbar);
  const appName = useSelector(SettingSelector.app_name);
  
  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add(themeFontSize);
  });

  const minisidebar = () => {
    document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini');
  };

  return (
    <Navbar
      expand="xl"
      className={`nav iq-navbar header-hover-menu left-border ${headerNavbar} ${navbarHide.join(
        ' '
      )}`}
    >
      <Container fluid className="navbar-inner">
        <Link to="/" className="navbar-brand">
          <img
            className="img-fluid"
            src={oneCareerLogo}
            alt="onecareer-logo"
            loading="lazy"
          />
          <h4 className="logo-title  ms-3 mb-0">{appName}</h4>
        </Link>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
          onClick={minisidebar}
        >
          <i className="icon d-flex">
            <svg width="20px" viewBox="0 0 24 24" className="icon-20">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>
      </Container>
    </Navbar>
  );
});

Headerpro.displayName = 'Headerpro';
export default Headerpro;
