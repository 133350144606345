import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Setting, WvContractEmailTemplate } from '../../interfaces';
import SkeletonCard from '../skeleton/SkeletonCard';
import GenericInfosCard from '../GenericInfosCard';
import { usePermissions } from '../../hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';
import WvContractEmailTemplateModal from './modal/WvContractEmailTemplateModal';

interface WvContractTemplateEmailProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

const WvContractTemplateEmail: React.FC<WvContractTemplateEmailProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const template = setting?.details as WvContractEmailTemplate
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
        ];
        return info1;
    }

    function getInfo3() {
        const info3 = [
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info3;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Titel/Betreff',
                value: template?.title || '-',
            },
            {
                label: 'Nachricht',
                value: template?.body || '-',
                isMultiline: true
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                {setting && <WvContractEmailTemplateModal
                    buttonName="Bearbeiten"
                    modalTitle={`${setting.title} bearbeiten`}
                    setting={setting}
                    onSubmitSuccess={onSubmitSuccess}
                    isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')}
                >
                </WvContractEmailTemplateModal>
                }
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>

                {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo3()}
                            firstColumnSize={4}
                        />
                    )}
 
                </Col>
            </Row>

            <Row>
                <Col>
                {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={2}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default WvContractTemplateEmail;
