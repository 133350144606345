import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';

interface ResetFiltersButtonProps {
  filters: { [key: string]: any };
  setFilters: any,
  label?: string;
}

// ResetFiltersButton is a component that provides a button to reset active filters.
// It checks if any filters are active (i.e., not null) and displays the button if they are.
// When clicked, it resets all filters to null using the 'setFilters' callback.
// The label for the button can be customized via the 'label' prop, defaulting to 'Filter zurücksetzen'.
const ResetFiltersButton: React.FC<ResetFiltersButtonProps> = ({ filters, setFilters, label = 'Filter zurücksetzen' }) => {
  const [filtersActive, setFiltersActive] = useState(false);

  useEffect(() => {
    setFiltersActive(Object.values(filters).some((value) => value !== null));
  }, [filters]);

  const resetFilters = () => {
    const resetState = Object.keys(filters).reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {} as { [key: string]: any });

    setFilters(resetState);
  };

  if (!filtersActive) return null;

  return (
    <Button variant="link" onClick={resetFilters} className="text-primary d-flex align-items-center">
      <FontAwesomeIcon icon={faUndo} className="ms-2 ms-lg-0" />
      <span className='d-none d-lg-block ms-2'>{label}</span>
    </Button>
  );
};

export default ResetFiltersButton;
