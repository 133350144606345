import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/pro-solid-svg-icons";


export function getApiKeyStatusIcon(
  status: number
) {
  let iconComponent;
  switch (Number(status)) {
    case 0:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          style={{ color: 'var(--bs-danger)' }}
          icon={faLock}
        />
      );
      break;
    case 1:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          style={{ color: 'var(--bs-success)' }}
          icon={faUnlock}
        />
      );
      break;
    default:
      break
  }
  return iconComponent;
}

export function getApiKeysStatusColor(
  status: number
): string {
  switch (Number(status)) {
    case 0:
      return 'text-danger';
    case 1:
      return 'text-success';
    default:
      return '';
  }
}
