import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Attendance } from '../../../interfaces';
import AddEditAttendanceModal from '../modal/AddEditAttendanceModal';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';
import { AttendanceStatusEnum, getEnumValue } from '../../../utils/enum';

interface AttendanceDetailsProps {
    isLoading?: boolean;
    attendance?: Attendance | null
    onSubmitSuccess: (attendanceId: number) => void;
}

const AttendanceDetails: React.FC<AttendanceDetailsProps> = ({
    isLoading,
    attendance,
    onSubmitSuccess
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: attendance?.title || '-',
            },
            {
                label: 'E-Mail',
                value: attendance?.email || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Status',
                value: getEnumValue(AttendanceStatusEnum, attendance?.status.toString()),
            },
            {
                label: 'Anmerkungen',
                value: attendance?.notes || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{attendance?.title}</h4>
                {attendance && <AddEditAttendanceModal
                    buttonName="Bearbeiten"
                    modalTitle={`${attendance.title} bearbeiten`}
                    attendance={attendance}
                    onSubmitSuccess={onSubmitSuccess}
                >
                </AddEditAttendanceModal>
                }
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default AttendanceDetails;
