import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
  SettingTypeMap
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Setting } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import WvContractTemplateEmail from '../../components/settings/WvContractEmailTemplate';
import GlobalMeasureDetails from '../../components/settings/GlobalMeasureDetails';


function SettingDetail() {
  const { settingId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [setting, setSetting] = useState<Setting | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read');

      if (hasPermission) {
        settingId && fetchSetting(settingId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [settingId, permissionsLoaded]);

  const fetchSetting = async (settingId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/settings/${settingId}`);
      const settingResponse = response.data;
      setSetting({...settingResponse, details: settingResponse.details ?? {}});
      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const handleModalSubmit = () => {
    setSetting(null)
    settingId && fetchSetting(settingId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              {settingId === SettingTypeMap.contractEmailTemplate && <WvContractTemplateEmail setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></WvContractTemplateEmail>}
              {settingId === SettingTypeMap.globalMeasureDetails && <GlobalMeasureDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></GlobalMeasureDetails>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default SettingDetail;
