import { faCircleCheck, faCircleExclamation, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Toast } from 'react-bootstrap';

interface NotificationToastProps {
  show: boolean;
  onClose: () => void;
  message: string;
  error?: boolean;
}

const NotificationToast: React.FC<NotificationToastProps> = ({
  show,
  onClose,
  error,
  message,
}) => {
  const customToastStyle = {
    backgroundColor: '#232D42',
    color: 'white',
    borderRadius: '20px',
    width: 'auto',
    zIndex: 2000
  };

  return (
    <Toast
      show={show}
      onClose={onClose}
      delay={5000}
      autohide
      className="position-fixed bottom-0 start-50 translate-middle-x mb-3"
      style={customToastStyle}
    >
      <Toast.Body>
        <div className="d-flex justify-content-center align-items-center">
          {error ? (
            <FontAwesomeIcon className="me-3 text-danger" icon={faCircleExclamation} />
          ) : (
            <FontAwesomeIcon className="me-3 text-success" icon={faCircleCheck} />
          )}
          <span className="me-3">{message}</span>{' '}
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            onClick={onClose}
            icon={faX}
          />
        </div>
      </Toast.Body>
    </Toast>
  );
};

export default NotificationToast;
