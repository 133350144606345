import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Row,
  Col,
  Table,
  Form,
  Button,
} from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';

import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../hooks/useSortableData';

import DynamicPagination from '../../components/table/DynamicPagination';
import { useFilters } from '../../hooks/participants/useFilters';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { useSelection } from '../../hooks/useSelection';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import { Product } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import SearchInput from '../../components/SearchInput';
import AddEditProductModal from '../../components/products/modal/AddEditProductModal';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

const mockProducts = [
  {
    id: 1,
    name: "Online Marketing SEO & SEA",
    title: "Online Marketing SEO & SEA",
    type: "combinations",
    status: "publish",
    slug: "online-marketing-seo-sea",
    lwId: "online-marketing-seo-sea",
    lwLink: "https://journey.onecareer.de/author/course?courseid=online-marketing-seo-sea",
    newLwSlug: "",
    newLwId: "",
    newLwLink: "",
    durationUes: 40,
    durationDays: 14,
    durationWeeks: 2
  },
  {
    id: 2,
    name: "Advanced Data Science",
    title: "Advanced Data Science",
    type: "courses",
    status: "draft",
    slug: "advanced-data-science",
    lwId: "advanced-data-science",
    lwLink: "https://journey.onecareer.de/author/course?courseid=advanced-data-science",
    newLwSlug: "adv-data-science",
    newLwId: "adv-data-science",
    newLwLink: "https://journey.onecareer.de/author/course?courseid=adv-data-science",
    durationUes: 60,
    durationDays: 30,
    durationWeeks: 4
  },
  {
    id: 3,
    name: "Project Management Basics",
    title: "Project Management Basics",
    type: "modules",
    status: "closed",
    slug: "project-management-basics",
    lwId: "project-management-basics",
    lwLink: "https://journey.onecareer.de/author/course?courseid=project-management-basics",
    newLwSlug: "proj-mgmt-basics",
    newLwId: "proj-mgmt-basics",
    newLwLink: "https://journey.onecareer.de/author/course?courseid=proj-mgmt-basics",
    durationUes: 20,
    durationDays: 10,
    durationWeeks: 1
  }
];


interface ProductsResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Product[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Products = memo(() => {
  const { companyId = 'oc' } = useParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [selectedComboSearchFilter, setSelectedComboSearchFilter] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { items: sortedProducts, requestSort } = useSortableData(products);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const { show, message, error, showToast, hideToast } = useToast();

  const getId = useCallback((product: Product) => product.id!, []);

  const {
    selectedItems: selectedProducts,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(products, getId);

  const {
    selectedCombo,
    setSelectedCombo,
    handleCertificationStatusChange,
    handlePublicationStatusChange,
    handleSelectedCombo,
    selectedCertificationStatusFilter,
    selectedPublicationStatusFilter,
    refetchTrigger
  } = useFilters();


  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    }
  };

  const fetchProducts = useCallback(async () => {
    setProducts([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&comboStatus=${selectedCombo}`;
    } else {
      if (selectedCertificationStatusFilter?.toString()) {
        queryParams += `&statusCertification=${selectedCertificationStatusFilter}`;
      }
      if (selectedPublicationStatusFilter?.toString()) {
        queryParams += `&status=${selectedPublicationStatusFilter}`;
      }

      if (searchQuery) {
        if (searchQuery) {
          queryParams += `&search=${encodeURIComponent(searchQuery)}`;
          if (selectedSearchFilter !== 'all') {
            queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
          }
        }
      }
    }

    try {
      const response = await ApiClient.get(`/participants${queryParams}`);
      const productsResponse = response.data as ProductsResponse;
      setTotalPages(productsResponse.amountPages);
      setProducts(mockProducts as any);
      setCurrentPage(productsResponse.page);
      setLimit(productsResponse.itemsPerPage);
      setTotalEntries(productsResponse.amountAllItems);
      setAvailableFilter(productsResponse.searchFilters)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [
    currentPage,
    selectedCombo,
    selectedCertificationStatusFilter,
    selectedPublicationStatusFilter,
    searchQuery,
    selectedComboSearchFilter
  ]);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewProducts, 'read');

      if (hasPermission) {
        fetchProducts();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    selectedCertificationStatusFilter,
    selectedPublicationStatusFilter,
    currentPage,
    searchQuery,
    selectedCombo,
    fetchProducts,
    permissionsLoaded,
    location,
    refetchTrigger
  ]);

  const handleModalSubmit = (participantId: number) => {
    navigate(`/${companyId}/participants/${participantId}`);
  };

  const handleProductUpdateSubmit = () => {
    fetchProducts();
  };

  const handleComboSearchFilterSelect = (newCombo: ComboButtonId) => {
    setSelectedComboSearchFilter(newCombo);
    setCurrentPage(1);
  };

  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  useEffect(() => {
    resetSearch();
  }, [location]);


  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Produkte</h3>
        <div className="d-flex">
          <AddEditProductModal
            modalTitle="Produkt hinzufügen"
            buttonName="Produkt hinzufügen"
            onSubmitSuccess={handleModalSubmit}
          ></AddEditProductModal>
        </div>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={setSelectedCombo}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewProducts, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewProducts, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('status')}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedProducts}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleProductUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='products'
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedProducts.map((product) => (
                <tr key={product.id} className="bg-white">
                  <td className="d-flex align-items-start long-text-column">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewProducts, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewProducts, 'delete')}
                      className="align-self-start me-3 pt-2"
                      type="checkbox"
                      checked={selectedProducts[product.id] ?? false}
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(product.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/products/${product.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {product.title}
                    </Link>
                  </td>
                  <td>{product.status}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedProducts.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Produkte gefunden</p>
          </div>
        )}
      </div>

      {sortedProducts.length > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Products;
