import React, { useState, useEffect } from 'react';
import { ApiClient } from '../../services/ApiClient';
import { MediaItem } from '../../interfaces';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';


const fetchMediaItemById = async (mediaId: number): Promise<MediaItem> => {
    const res = await ApiClient.get(`/media/${mediaId}`);
    return res.data;
};

const BreadcrumbItem = ({ media, showDivider, navigate, onBreadcrumbsClick, isMediaPicker }: { media: MediaItem; showDivider: boolean, navigate: NavigateFunction, onBreadcrumbsClick?: (mediaId: number) => void, isMediaPicker: boolean }) => {
    const { companyId = 'oc' } = useParams();
    const handleClick = () => {
        if (isMediaPicker) {
            onBreadcrumbsClick && onBreadcrumbsClick(media.id);
        } else {
            navigate(`/${companyId}/media/${media.id}`);
        }
    };

    return (
        <span>
            <span className={`cursor-pointer ${!showDivider && 'text-primary'}`} onClick={handleClick}>{media.title}</span>
            {showDivider && <FontAwesomeIcon className='mx-2' icon={faChevronRight} />}
        </span>
    );
};

const Breadcrumbs = ({ currentFolderId, onBreadcrumbsChange, isMediaPicker = false, onBreadcrumbsClick, }: { currentFolderId: number, onBreadcrumbsChange: (items: MediaItem[]) => void, onBreadcrumbsClick?: (folderId: number) => void, isMediaPicker?: boolean }) => {
    const [breadcrumbs, setBreadcrumbs] = useState<MediaItem[]>([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchHierarchy = async (folderId: number, accumulator: MediaItem[] = []) => {
            if (folderId === 0) {
                accumulator.reverse();
                const medienItem = { title: 'Medien', id: 0 } as any;
                accumulator.unshift(medienItem);
                setBreadcrumbs(accumulator);
                onBreadcrumbsChange(accumulator);
                return;
            }

            try {
                const mediaItem = await fetchMediaItemById(folderId);
                accumulator.push(mediaItem);
                await fetchHierarchy(mediaItem.folderId, accumulator);
            } catch (error) {
                console.error('Error fetching media item:', error);
            }
        };

        fetchHierarchy(currentFolderId);
    }, [currentFolderId]);

    return (
        <div>
            {breadcrumbs.map((media, index) => (
                <BreadcrumbItem
                    key={media.id}
                    media={media}
                    showDivider={index < breadcrumbs.length - 1}
                    navigate={navigate}
                    onBreadcrumbsClick={onBreadcrumbsClick}
                    isMediaPicker={isMediaPicker}
                />
            ))}
        </div>
    );
};

export default Breadcrumbs;
