import axios, { AxiosRequestConfig } from 'axios';

export class ApiClient {
  static getRequestData() {
    const token = localStorage.getItem('accessToken');
    const companyId = localStorage.getItem('companyId') || 'oc';
    if (!token) {
      throw new Error(
        `Trying to access protected endpoint but no auth token available!`
      );
    }
    return { headers: { Authorization: `Bearer ${token}` }, companyId };
  }

  static get(endpoint: string) {
    const requestData = ApiClient.getRequestData();
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`,
      {
        headers: requestData.headers,
      }
    );
  }

  static post(endpoint: string, body: any, config?: AxiosRequestConfig) {
    const requestData = ApiClient.getRequestData();
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`,
      body,
      {
        headers: requestData.headers,
        ...config,
      }
    );
  }

  static put(endpoint: string, body: any) {
    const requestData = ApiClient.getRequestData();
    return axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`,
      body,
      {
        headers: requestData.headers,
      }
    );
  }

  static delete(endpoint: string) {
    const requestData = ApiClient.getRequestData();
    return axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`,
      {
        headers: requestData.headers,
      }
    );
  }
}
