import { Deal, Permissions } from "../interfaces";
import { moduleTranslations } from "./enum";
import { FieldConfig } from '../interfaces';
import { SelectOption } from "../components/form/SelectWithSearch";

export const formatEuro = (value: number | string) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(Number(value));
};

export const formatNumber = (value: string) => {
  return parseFloat(value).toString();
};

export const formatGermanDate = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return null;
  }

  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const formattedTime = new Intl.DateTimeFormat('de-DE', optionsTime).format(
    date
  );

  return `${formattedDate} - ${formattedTime} Uhr`;
};

export const formatDateWithoutTime = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return dateStr;
  }
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  return formattedDate;
};

export function formatIban(iban: string): string {
  const parts = iban.split(' ');

  if (parts.length <= 2) {
    return iban;
  }

  const firstPart = parts[0];
  const lastPart = parts[parts.length - 1];

  const middleParts = parts.slice(1, parts.length - 1);
  let maskedMiddleParts = middleParts
    .map((part) => 'X'.repeat(part.length))
    .join(' ');

  return `${firstPart} ${maskedMiddleParts} ${lastPart}`;
}

export function normalizeString(str: string) {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div?.textContent!.trim();
}

export function normalizeJsonString(jsonString: string): string {
  const parsedObj = JSON.parse(jsonString);
  const sortedObj = sortObjectKeys(parsedObj);
  return JSON.stringify(sortedObj);
}

function sortObjectKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = sortObjectKeys(obj[key]);
        return sortedObj;
      }, {} as { [key: string]: any });
  } else {
    return obj;
  }
}

export const generateRandomHex = () => {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join(
    ''
  );
};

export function getModuleList(input: any): string {
  if (input === '*') return '*';

  if (!input) {
    return "-"
  }

  try {
    const translatedKeys = Object.keys(input).map((key) => {
      return moduleTranslations[key as keyof Permissions] || key;
    });
    return translatedKeys.join(', ');
  } catch (error) {
    console.error('Invalid input:', error);
    return '';
  }
}

export function getModuleArray(input: any): { key: string, rights: string[] }[] | string {
  if (!input) return '';

  if (input === '*') return '*';
  try {
    const parsedInput = input ?? '';
    const keys = Object.keys(parsedInput).map(key => ({
      key,
      rights: Object.entries(parsedInput[key]).filter(([, value]) => value).map(([right]) => right)
    }));
    return keys;
  } catch (error) {
    console.error('Invalid input:', error);
    return [];
  }
}

// Extracts the initals from a name. Uses only the first and the last name. Ignores middle names.
export function getInitials(name: string): string {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const nameParts = name.trim().split(' ');
  const filteredNameParts = nameParts.filter(part => part.length > 0);

  if (filteredNameParts.length === 0) {
    return '';
  }

  if (filteredNameParts.length === 1) {
    return filteredNameParts[0][0].toUpperCase();
  }

  const firstInitial = filteredNameParts[0][0]?.toUpperCase() ?? '';
  const lastInitial = filteredNameParts[filteredNameParts.length - 1][0]?.toUpperCase() ?? '';

  return `${firstInitial}${lastInitial}`;
}

export const parseDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('.').map(Number);
  return new Date(year, month - 1, day);
};

export const calculateProgressFromStartAndEndDate = (start: string, end: string, abortDate: string): number => {
  const startDate = parseDate(start);
  const endDate = parseDate(end);
  const today = new Date();

  // Parse abort date if valid, else set to null
  const abort = abortDate && abortDate !== '0000-00-00 00:00:00' ? parseDate(abortDate) : null;

  // Calculate the total duration from the start to the original end date (in days)
  const totalDuration = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

  // Determine the effective current date for the progress calculation
  let effectiveCurrentDate = today;
  if (abort && abort.getTime() < today.getTime()) {
    effectiveCurrentDate = abort; // Stop progress at abort date if in the past
  }

  // Calculate elapsed time from the start date to today or the abort date (in days)
  const elapsedDuration = (effectiveCurrentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

  // If the elapsed time is less than 0 (i.e., we are before the start date), return 0% progress
  if (elapsedDuration < 0) {
    return 0;
  }

  // If the elapsed time exceeds the total duration, cap the progress at 100%
  if (elapsedDuration >= totalDuration) {
    return 100;
  }

  // Calculate progress as a percentage of the total duration
  return Math.round((elapsedDuration / totalDuration) * 100);
};

export function getDealLabel(startDate: string, isCurrentDeal: boolean): string {
  const date = new Date(startDate);
  const month = date.toLocaleString('de-DE', { month: 'short' });
  const year = date.getFullYear();
  return isCurrentDeal ? `Aktueller Deal ${month}. ${year}` : `Deal ${month}. ${year}`;
}

export function formatDate(dateString: string, format: string) {
  const date = new Date(dateString);

  const padZero = (number: number) => (number < 10 ? '0' : '') + number;

  const replacements: any = {
    Y: date.getFullYear(),
    m: padZero(date.getMonth() + 1),
    d: padZero(date.getDate()),
    H: padZero(date.getHours()),
    i: padZero(date.getMinutes()),
    s: padZero(date.getSeconds())
  };

  return format.replace(/Y|m|d|H|i|s/g, match => replacements[match]);
}

export function formatApiKey(str: string) {
  if (!str) {
    return ''
  }
  if (str.length <= 10) {
    return str;
  }
  return str.slice(0, 10) + '**********';
}

/**
 * Retrieves a specific field configuration object by its resource name.
 * 
 * This function searches through an array of `FieldConfig` objects to find
 * a configuration that matches the given `resourceName`.
 * 
 * @param {FieldConfig[] | undefined} fieldConfigs - An array of field configuration objects.
 *   It may be undefined or empty.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {FieldConfig} - The matching `FieldConfig` object if found, or an empty object if not.
 *   The function casts the empty object as `FieldConfig` for consistency in return type.
 */
export const getFieldConfigByResourceName = (
  fieldConfigs: FieldConfig[] | undefined,
  resourceName: string
) => {
  if (!fieldConfigs || fieldConfigs.length === 0) {
    return {} as FieldConfig;
  }
  return fieldConfigs.find((config) => config.resourceName === resourceName);
};

/**
 * Retrieves select options for a given resource name from field configurations.
 * 
 * This function extracts options from a `FieldConfig` object that matches
 * the provided `resourceName` within the array of `FieldConfig` objects.
 * It converts the options object into an array of `SelectOption` objects,
 * which contain `value` and `label` pairs suitable for use in a dropdown.
 * 
 * @param {FieldConfig[]} fieldConfigs - An array of field configuration objects.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {SelectOption[]} - An array of `SelectOption` objects where each object
 *   represents a value-label pair from the matching `FieldConfig` options.
 *   Returns an empty array if no matching field configuration or options are found.
 */
export const getFieldOptions = (
  fieldConfigs: FieldConfig[],
  resourceName: string
): SelectOption[] => {
  const options = getFieldConfigByResourceName(fieldConfigs, resourceName)?.options ?? {};
  return Object.entries(options).map(([value, label]) => ({ value, label }));
};

/**
 * Retrieves the label for a given resource name from field configurations.
 * 
 * This function finds a `FieldConfig` object that matches the provided `resourceName`
 * within the array of `FieldConfig` objects and extracts its `fieldLabel`.
 * 
 * @param {FieldConfig[]} fieldConfigs - An array of field configuration objects.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {string} - The `fieldLabel` of the matching `FieldConfig` object.
 *   Returns an empty string if no matching configuration is found.
 */
export const getFieldLabel = (
  fieldConfigs: FieldConfig[],
  resourceName: string
): string => {
  return getFieldConfigByResourceName(fieldConfigs, resourceName)?.fieldLabel || '';
};

/**
 * Adds a specified prefix to each filter in an array of filter strings.
 * 
 * This function ensures that each filter string includes a prefix.
 * If a filter already contains a dot (.), indicating that it already has a prefix,
 * it is returned as-is. Otherwise, the specified prefix is prepended to the filter.
 * 
 * @param {string[]} filters - An array of filter strings to which the prefix will be applied.
 * @param {string} prefix - The prefix to add to each filter string.
 * 
 * @returns {string[]} - A new array of filter strings with the prefix applied where necessary.
 *   Filters that already contain a prefix are not modified.
 */
export function addPrefixToFilters(filters: string[], prefix: string) {
  return filters.map((filter) => {
    return filter.includes('.') ? filter : `${prefix}.${filter}`;
  });
}

/**
 * Formats a `Deal` object into a string representation that includes the month and year
 * of the deal's start date.
 * 
 * Example output: "Deal Oktober 2023"
 * 
 * @param {Deal} deal - The deal object containing the `start` date.
 * @returns {string} - A formatted string with the deal's start month and year, or an empty string if no start date is provided.
 */
export function formatDealMonthYear(deal: Deal): string {
  if (!deal.start) {
    return '';
  }
  const startDate = new Date(deal.start);

  const monthNames = [
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
    'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ];

  const month = monthNames[startDate.getMonth()];
  const year = startDate.getFullYear();
  return `Deal ${month} ${year}`;
}