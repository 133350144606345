import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { formatSizeUnits, getMediaIcon } from '../../utils/media/utils';
import { MediaItemWithProgress } from '../../interfaces';

interface TemporaryFilesProps {
  temporaryFiles: MediaItemWithProgress[];
}

const TemporaryFiles: React.FC<TemporaryFilesProps> = ({ temporaryFiles }) => {
  return (
    <>
      {temporaryFiles.map((file, index) => (
        <React.Fragment key={`temp-${index}`}>
          <tr className="bg-white cursor-pointer">
            <td>
              <div className="text-black py-3 ps-2 ms-5">
                {getMediaIcon(file)} {file.title}
              </div>
            </td>
            <td>{file.size ? formatSizeUnits(file.size) : '-'}</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={3} className="p-0 m-0">
              <ProgressBar now={file.progress} variant="success" style={{ height: '3px' }} />
            </td>
          </tr>
        </React.Fragment>
      ))}
    </>
  );
};

export default TemporaryFiles;
